import { Button, Input, Space, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useNavigate, useParams } from "react-router-dom";
import {BACKEND_DOMAIN} from "../../../features/auth/authService";


export default function MonthlyLectures() {
	const [data, setData] = useState([]);
	const [userInfo, setUserInfo] = useState(null);
	const searchInput = useRef(null);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");
	const navigate = useNavigate();
	const { userId } = useParams(); // Extraer el userId de la URL

	const handlePayClick = () => {
		navigate(`/menu_apr/pay/${userId}`);
	};

	const handleCancelClick = (userId) => {
		navigate(`/menu_apr/cancel/${userId}`);
	};

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
	};

	const handleAgreementClick = (invoiceId) => {
		navigate(`/menu_apr/renegotiate/${userId}/${invoiceId}`);
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					ref={searchInput}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ marginBottom: 8, display: "block" }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}
					>
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ""}
				/>
			) : (
				text
			),
	});

	const columns = [
		{
			title: "Año",
			dataIndex: "year",
			key: "year",
			width: "10%",
			...getColumnSearchProps("year"),
		},
		{
			title: "Mes",
			dataIndex: "month",
			key: "month",
			width: "10%",
			...getColumnSearchProps("month"),
		},
		{
			title: "M³",
			dataIndex: "cubic_meter",
			key: "cubic_meter",
			width: "10%",
			...getColumnSearchProps("cubic_meter"),
		},
		{
			title: "Total mes",
			dataIndex: "total_month",
			key: "total_month",
			width: "15%",
			...getColumnSearchProps("total_month"),
		},
		{
			title: "Saldo por pagar",
			dataIndex: "outstanding_balance",
			key: "outstanding_balance",
			width: "15%",
			...getColumnSearchProps("outstanding_balance"),
		},
		{
			title: "Acción",
			dataIndex: "edit",
			key: "edit",
			render: (_, record, index) => {
				const isFirstRecord = index === 0;
				return (
					<div className="actions-container">
						{isFirstRecord && record.outstanding_balance > 0 && (
							<a className="active-link" onClick={() => handlePayClick(record.key)}>
								Pagar
							</a>
						)}
						{record.abono !== 0 && (
							<a className="active-link" onClick={() => handleCancelClick(record.key)}>
								Anular Pago
							</a>
						)}
						{record.outstanding_balance > 0 && (<a className="active-link" onClick={() => handleAgreementClick(record.key)}>
							Convenio
						</a>)}
					</div>
				);
			},
		},
	];

	useEffect(() => {
		const fetchBoletas = async () => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				const token = user?.access;

				if (!token) {
					throw new Error("No se encontró el token en el localStorage");
				}

				const response = await fetch(`${BACKEND_DOMAIN}/api/v1/boleta/user/${userId}`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});

				const result = await response.json();

				if (response.ok) {
					setUserInfo(result.usuario);

					const mappedData = result.boletas.map((boleta) => ({
						key: boleta.id,
						year: new Date(boleta.fecha_emision_boleta).getFullYear().toString(),
						month: new Date(boleta.fecha_emision_boleta).toLocaleString("es-ES", { month: "long" }),
						cubic_meter: boleta.tarifa_usuario_fk.metros_cubicos,
						total_month: boleta.costo_total_iva_reajustado,
						outstanding_balance: 0, // Inicialmente en 0
						pagado: boleta.boleta_activa,
						abono: boleta.abono,
						fecha_emision_boleta: new Date(boleta.fecha_emision_boleta),
						saldo_pendiente: boleta.saldo_pendiente,
					}));

					// Ordenar las boletas por fecha_emision_boleta de forma ascendente
					const sortedData = mappedData.sort((a, b) => a.fecha_emision_boleta - b.fecha_emision_boleta);

					// Calcular el saldo acumulado individualmente
					sortedData.forEach((currentBoleta, index) => {
						const balanceUpToCurrent = sortedData
							.slice(0, index + 1) // Filtrar las boletas hasta la actual
							.reduce((acc, boleta) => {
								const currentBalance = (boleta.total_month - boleta.abono + boleta.saldo_pendiente - boleta.abono) > 0 ? boleta.total_month - boleta.abono + boleta.saldo_pendiente - boleta.abono : 0;
								return acc + currentBalance;
							}, 0);
						currentBoleta.outstanding_balance = balanceUpToCurrent;
					});

					// Invertir el orden de los datos
					const reversedData = sortedData.reverse();

					setData(reversedData);
				} else {
					console.error("Error al obtener las boletas:", result);
				}
			} catch (error) {
				console.error("Error en la petición:", error.message);
			}
		};

		fetchBoletas();
	}, [userId]);

	return (
		<div className="menu-container">
			<div className="menu-content-container">
				<div className="top-gray-container small">
					<div className="user-info-container">
						{userInfo && (
							<>
								<h3 className="info-text">
									Nombre: <span className="marked">{`${userInfo.first_name} ${userInfo.last_name}`}</span>
								</h3>
								<h3 className="info-text">
									N° Medidor: <span className="marked">{userInfo.meter_addr}</span>
								</h3>
								<h3 className="info-text">
									RUT: <span className="marked">{userInfo.rut}</span>
								</h3>
								<h3 className="info-text">
									Dirección: <span className="marked">{userInfo.address}</span>
								</h3>
							</>
						)}
					</div>
				</div>
				<Table columns={columns} dataSource={data} />
			</div>
		</div>
	);
}
