import React, {useState} from "react";
import {useParams, useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
import {BACKEND_DOMAIN} from "../../features/auth/authService";
import saveDisk from "../../images/save-disk.svg";
import cancelIcon from "../../images/cancel-icon.svg";

const AddLecture = () => {
	const {meterId} = useParams(); // Extraer el meterId de la URL
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		meter_number: "",
		valve_status: "",
		voltage: "",
		read_date: "",
		read_time: "",
	});

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const formatReadDate = () => {
		if (formData.read_date && formData.read_time) {
			const date = dayjs(formData.read_date).format("YYYY-MM-DD");

			// Concatenamos la fecha con la hora seleccionada para que dayjs la entienda
			const combinedDateTime = `${formData.read_date}T${formData.read_time}:00`;
			const time = dayjs(combinedDateTime).format("HH:mm:ss");

			return `${date}T${time}Z`; // Formato ISO 8601
		}
		return null;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formattedDate = formatReadDate();
		// console.log(formattedDate);
		const data = {
			meter_number: parseInt(formData.meter_number),
			valve_status: formData.valve_status,
			voltage: parseFloat(formData.voltage),
			read_date: formattedDate,
			assigned_meter: parseInt(meterId), // Usamos el meterId extraído de la URL
		};

		try {
			const response = await axios.post(`${BACKEND_DOMAIN}/api/v1/crear-consumo-medidor/`, data);
			navigate("/menu_apr"); // Navega de vuelta al menú después de enviar
		} catch (error) {
			console.error("Error:", error);
		}
	};

	return (
		<div className="menu-container">
			<div className="menu-content-container">
				<div className="my-profile-container">
					<div className="form-container">
						<h1 className="form-title">Agregar Lectura del Medidor</h1>
						<form className="add-lecture-form" onSubmit={handleSubmit}>
							<div className="input-container">
								<label>Lectura de medidor:</label>
								<input
									type="number"
									name="meter_number"
									value={formData.meter_number}
									onChange={handleChange}
									placeholder="Ingrese los m³"
									required
								/>
							</div>

							<div className="input-container">
								<label>Estado de la válvula:</label>
								<select name="valve_status" value={formData.valve_status} onChange={handleChange} required>
									<option value="">Seleccione estado de la válvula</option>
									<option value="Opened">Abierta</option>
									<option value="Closed">Cerrada</option>
								</select>
							</div>

							<div className="input-container">
								<label>Voltaje:</label>
								<input type="number" name="voltage" value={formData.voltage} onChange={handleChange} step="0.1" placeholder="Ingrese voltaje" required />
							</div>

							<div className="input-container">
								<label>Fecha de lectura:</label>
								<input type="date" name="read_date" value={formData.read_date} onChange={handleChange} required />
							</div>

							<div className="input-container">
								<label>Hora de lectura:</label>
								<input type="time" name="read_time" value={formData.read_time} onChange={handleChange} required />
							</div>

							<div className="buttons-container">
								<button className="btn-save" type="submit">
									<img src={saveDisk} className="save-image" />
									Añadir
								</button>
								<button className="btn-cancel" type="button" onClick={() => navigate("/menu_apr")}>
									<img src={cancelIcon} className="cancel-image" />
									Cancelar
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddLecture;
