import React, { useEffect, useState } from "react";
import "../../../../stylesheets/pay.scss";
import { useNavigate, useParams } from "react-router-dom";
import saveDisk from "../../../../images/save-disk.svg";
import cancelIcon from "../../../../images/cancel-icon.svg";
import axios from "axios";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import {BACKEND_DOMAIN} from "../../../../features/auth/authService";


export default function Pay() {
	const navigate = useNavigate();
	const { userId } = useParams(); // Extraer el userId de la URL
	const [userInfo, setUserInfo] = useState({
		id: "",
		rut: "",
		first_name: "",
		last_name: "",
		second_last_name: "",
		email: "",
		birthday: "",
		boleta: "",
		cargos: [],
		cuota_convenio_pendiente: "",
		cargo_fijo_arranque: "",
		cargos_fijos_usuario: "",
		meter_addr: "",
	});

	const [chargesSummary, setChargesSummary] = useState({
		corteReposicion: 0,
		multa: 0,
		cuota: 0,
		incorporacion: 0,
		otros: 0,
		haberes: 0,
	});

	const [fixedCharges, setFixedCharge] = useState({
		monto_cargo_fijo_alcantarillado: "",
		monto_alcantarillado: "",
		monto_cargo_fijo_agua: "",
	})

	const [paymentMethod, setPaymentMethod] = useState([]);


	const [payFormData, setPayFormData] = useState({
		abono: "",
		fecha_pago: "",
		medio_pago: "",
		forma_pago: "",
	});

	useEffect(() => {
		const fetchDetailBoletas = async () => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				const token = user?.access;

				if (!token) throw new Error("No se encontró el token en el localStorage");

				const response = await fetch(
					`${BACKEND_DOMAIN}/api/v1/boleta/user/last-detail/${userId}/`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`
						}
					}
				);

				const result = await response.json();
				console.log("result", result)
				if (response.ok && result.usuario) {
					setUserInfo({
						id: result.usuario.id || "",
						rut: result.usuario.rut || "",
						first_name: result.usuario.first_name || "",
						last_name: result.usuario.last_name || "",
						second_last_name: result.usuario.second_last_name || "",
						email: result.usuario.email || "",
						address: result.usuario.address || "",
						birthday: result.usuario.birthday || "",
						boleta: result.boleta || "",
						cargos: result.cargos || [],
						cuota_convenio_pendiente: result.cuota_convenio_pendiente || 0,
						cargo_fijo_arranque: result.cargo_fijo_arranque || "",
						cargos_fijos_usuario: result.cargos_fijos_usuario || "",
						meter_addr: result.usuario.meter_addr || "",

					});
				} else {
					console.error("Error al obtener los detalles:", result);
				}

			} catch (error) {
				console.error("Error en la petición:", error.message);
			}
		};

		fetchDetailBoletas();
	}, [userId]);

	useEffect(() => {
		if (!userInfo.cargos || userInfo.cargos.length === 0) return; // Evitar cálculos innecesarios si no hay cargos

		let summary = {
			corteReposicion: 0,
			multa: 0,
			cuota: 0,
			incorporacion: 0,
			otros: 0,
			haberes: 0,
		};

		userInfo.cargos.forEach(charge => {
			const descripcion = charge.movimiento_fk?.descripcion?.toLowerCase(); // Convertir a minúsculas
			if (descripcion.includes('corte') || descripcion.includes('reposicion')) {
				summary.corteReposicion += charge.costo;
			} else if (descripcion.includes('multa')) {
				summary.multa += charge.costo;
			} else if (descripcion.includes('cuota')) {
				summary.cuota += charge.costo;
			} else if (descripcion.includes('incorporacion')) {
				summary.incorporacion += charge.costo;
			} else {
				summary.otros += charge.costo;
			}

			const tipo = charge.movimiento_fk?.tipo?.toLowerCase()
			if (tipo.includes('haber')) {
				summary.haberes += charge.costo
			}


		});

		setChargesSummary(summary);


	}, [userInfo]);

	console.log("userInfo.cargos", userInfo.cargos)



	useEffect(() => {
		const fetchMedioPago = async () => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				const token = user?.access;

				if (!token) throw new Error("No se encontró el token en el localStorage");

				const response = await fetch(
					`${BACKEND_DOMAIN}/api/v1/mis-medios-de-pago/`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				);

				const result = await response.json();
				if (response.ok) {
					setPaymentMethod(result);
				} else {
					console.error("Error al obtener los métodos de pago:", result);
				}
			} catch (error) {
				console.error("Error en la petición:", error.message);
			}
		};

		fetchMedioPago();
	}, []);

	// Función para actualizar el monto de los cargos fijos
	const updateFixedCharges = () => {
		if (userInfo.cargos_fijos_usuario.cargos_fijos_alcantarillado === "POR ARRANQUE") {
			setFixedCharge((prevState) => ({
				...prevState,
				monto_cargo_fijo_alcantarillado: userInfo?.cargo_fijo_arranque?.cargo_fijo_alcantarillado,
				monto_alcantarillado: 0
			}));
		} else if (userInfo.cargos_fijos_usuario.cargos_fijos_alcantarillado === "POR M3") {
			const new_monto_alcantarillado = userInfo?.usuario?.boleta?.tarifa_usuario_fk?.metros_cubicos * userInfo?.cargo_fijo_arranque?.cargo_fijo_alcantarillado
			setFixedCharge((prevState) => ({
				...prevState,
				monto_cargo_fijo_alcantarillado: 0,
				monto_alcantarillado: new_monto_alcantarillado
			}));
		}

		if (userInfo.cargos_fijos_usuario.cargos_fijos_agua === "POR ARRANQUE") {
			setFixedCharge((prevState) => ({
				...prevState,
				monto_cargo_fijo_agua: userInfo?.cargo_fijo_arranque?.cargo_fijo_mantencion,
			}));
		} else if (userInfo.cargos_fijos_usuario.cargos_fijos_agua === "POR M3") {
			setFixedCharge((prevState) => ({
				...prevState,
				monto_cargo_fijo_agua: userInfo?.cargo_fijo_arranque?.cargo_fijo_mantencion * userInfo?.usuario?.boleta?.tarifa_usuario_fk?.metros_cubicos,
			}));
		}
	};

	// Actualizar los cargos fijos cada vez que userInfo cambie
	useEffect(() => {
		updateFixedCharges();
	}, [userInfo]);

	const handleChange = (e) => {
		setPayFormData((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		// Obtener el token desde localStorage
		const user = JSON.parse(localStorage.getItem("user"));
		const token = user ? user.access : null;

		if (!token) {
			toast.error("Token de autenticación no disponible");
			return;
		}

		const formattedDate = dayjs(payFormData.fecha_pago).format("YYYY-MM-DD[T]HH:mm:ssZ");

		const dataToSend = {
			abono: payFormData.abono,  // Asumiendo que 'abono' se obtiene del formulario
			fecha_pago: formattedDate,
			medio_pago: payFormData.medio_pago,  // Asumiendo que 'medio_pago' también viene del formulario
			forma_pago: payFormData.forma_pago
		};

		try {
			await axios.put(
				`${BACKEND_DOMAIN}/api/v1/boleta/edit/${userInfo?.boleta?.id}/`,
				dataToSend,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			toast.success("Boleta pagada con éxito");
			navigate("/menu_apr/clients_movements");
		} catch (error) {
			console.error("Error al pagar la boleta:", error.response ? error.response.data : error.message);
			toast.error("Error al pagar la boleta");
		}
	};

	return (
		<div className="menu-container">
			<div className="menu-content-container">
				<div className="top-gray-container small">
					<div className="user-info-container">
						<h3 className="info-text">Nombre: <span className="marked">{`${userInfo.first_name} ${userInfo.last_name}`}</span></h3>
						<h3 className="info-text">N° Medidor: <span className="marked">{userInfo.meter_addr}</span></h3>
						<h3 className="info-text">RUT: <span className="marked">{userInfo.rut}</span></h3>
						<h3 className="info-text">Dirección: <span className="marked">{userInfo.address}</span></h3>
					</div>
				</div>
				<div className="payment-form-container">
					<h1 className="form-title">Ingresar pago</h1>
					<div className="grid-container">
						<div className="charges-container">
							<h2 className="form-subtitle">Detalles de cargos:</h2>
							<div className="index-list">
								<div className="index-item">
									<span className="label">Consumo de agua</span>
									<span className="dots"></span>
									<span className="value">{userInfo?.boleta?.tarifa_usuario_fk?.costo_sin_descuento}</span>
								</div>
								<div className="index-item">
									<span className="label">Cargo fijo agua</span>
									<span className="dots"></span>
									<span className="value">{fixedCharges.monto_cargo_fijo_alcantarillado}</span>
								</div>
								<div className="index-item">
									<span className="label">Cargo fijo alcantarillado</span>
									<span className="dots"></span>
									<span className="value">{fixedCharges.monto_cargo_fijo_alcantarillado}</span>
								</div>
								<div className="index-item">
									<span className="label">Alcantarillado</span>
									<span className="dots"></span>
									<span className="value">{fixedCharges.monto_alcantarillado}</span>
								</div>
								<br></br>
								<div className="index-item">
									<span className="label">Saldo anterior</span>
									<span className="dots"></span>
									<span className="value">{userInfo?.boleta?.saldo_pendiente}</span>
								</div>
								<div className="index-item">
									<span className="label">Convenio de pago</span>
									<span className="dots"></span>
									<span className="value">{userInfo?.cuota_convenio_pendiente}</span>
								</div>
								<div className="index-item">
									<span className="label">Corte/Reposición</span>
									<span className="dots"></span>
									<span className="value">{chargesSummary.corteReposicion}</span>
								</div>
								<div className="index-item">
									<span className="label">Multa</span>
									<span className="dots"></span>
									<span className="value">{chargesSummary.multa}</span>
								</div>
								<div className="index-item">
									<span className="label">Cuota</span>
									<span className="dots"></span>
									<span className="value">{chargesSummary.cuota}</span>
								</div>
								<div className="index-item">
									<span className="label">Incorporación</span>
									<span className="dots"></span>
									<span className="value">{chargesSummary.incorporacion}</span>
								</div>
								<div className="index-item">
									<span className="label">Otros</span>
									<span className="dots"></span>
									<span className="value">{chargesSummary.otros}</span>
								</div>
							</div>
						</div>
						<div className="other-details">
							<h2 className="form-subtitle">Otros:</h2>
							<div className="index-list">
								<div className="index-item">
									<span className="label">IVA</span>
									<span className="dots"></span>
									<span className="value">{userInfo?.boleta?.costo_total_con_iva - userInfo?.boleta?.costo_total_sin_descuento}</span>
								</div>
								<div className="index-item">
									<span className="label">Ajuste sencillo</span>
									<span className="dots"></span>
									<span className="value">{userInfo?.boleta?.costo_total_iva_reajustado - userInfo?.boleta?.costo_total_con_iva}</span>
								</div>
								<h2 className="form-subtitle">Detalles de pago:</h2>
								<form className="form-inputs" onSubmit={handleSubmit}>
									<div className="input-container" >
										<span className="label">Fecha de pago</span>
										<input
											type="datetime-local"
											id="fecha_pago"
											name="fecha_pago"
											value={dayjs(payFormData.fecha_pago).format("YYYY-MM-DDTHH:mm")}
											onChange={handleChange}
										/>
									</div>
									<div className="input-container">
										<span className="label">Tipo de pago</span>
										<select
											id="medio_pago"
											name="medio_pago"
											value={payFormData.medio_pago}
											onChange={handleChange}
										>
											<option key={-1} value={""}>Seleccione una opción</option>

											{paymentMethod.map((method) => (
												<option key={method.id} value={method.id}>
													{method.descripcion}
												</option>
											))}
										</select>
									</div>
									<div className="input-container" >
										<span className="label">Forma de pago</span>
										<select id="forma_pago" name="forma_pago" onChange={handleChange} value={payFormData.forma_pago} >
											<option key={0} value="">Seleccione forma de pago</option>
											<option key={1} value="CONTADO">Contado</option>
											<option key={2} value="CREDITO">Crédito</option>
											<option key={3} value="CHEQUE A FECHA">Cheque a fecha</option>
										</select>
									</div>
									<div className="input-container">
										<span className="label">N° de cheque</span>
										<input type="number" name="chek-number" ></input>
									</div>
									<div className="input-container" >
										<span className="label">Monto a pagar</span>
										<input
											className="input-read-only"
											type="number"
											name="total_amount"
											value={userInfo?.boleta?.costo_total_iva_reajustado + userInfo?.boleta?.saldo_pendiente - userInfo?.boleta?.abono}
											readOnly
										/>
									</div>
									<div className="horizontal-bottom-container">
										<div className="input-container" >
											<span className="label">Dinero entregado</span>
											<input
												type="number"
												id="abono"
												name="abono"
												value={payFormData.abono}
												onChange={handleChange}
												placeholder="Ingrese el monto"
											/>
										</div>

										<div className="input-container">
											<span className="label">Vuelto</span>
											<input className="input-read-only" type="number" readOnly value={(payFormData.abono - (userInfo?.boleta?.costo_total_iva_reajustado + userInfo?.boleta?.saldo_pendiente - userInfo?.boleta?.abono)) > 0 ? (payFormData.abono - (userInfo?.boleta?.costo_total_iva_reajustado + userInfo?.boleta?.saldo_pendiente - userInfo?.boleta?.abono)) : 0}></input>
										</div>
									</div>
									<div className="form-footer-container">
										<div className="labels-container">
											<span className="label">Total boleta: {userInfo?.boleta?.costo_total_iva_reajustado + userInfo?.boleta?.saldo_pendiente - userInfo?.boleta?.abono}</span>
											<span className="label">Pagado real: {"0"}</span>
											<span className="label">Por Pagar: {userInfo?.boleta?.costo_total_iva_reajustado + userInfo?.boleta?.saldo_pendiente}</span>
										</div>
										<div className="buttons-container">
											<button type="button" className="btn-cancel" onClick={() => navigate("/menu_apr")}>
												<img src={cancelIcon} className="cancel-image" />
												Cancelar
											</button>
											<button type="submit" className="btn-save">
												<img src={saveDisk} className="save-image" />
												Guardar
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div className="discount-details">
							<h2 className="form-subtitle">Detalles de descuentos:</h2>
							<div className="index-list">
								<div className="index-item">
									<span className="label">Subsidios</span>
									<span className="dots"></span>
									<span className="value">{userInfo?.boleta?.costo_total_con_descuento > 0 ? userInfo?.boleta?.costo_total_sin_descuento - userInfo?.boleta?.costo_total_con_descuento : 0}</span>
								</div>
								<div className="index-item">
									<span className="label">Otros descuentos</span>
									<span className="dots"></span>
									<span className="value">{chargesSummary.haberes}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
