import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import saveDisk from "../../../../images/save-disk.svg";
import cancelIcon from "../../../../images/cancel-icon.svg";
import { Button, Input, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { toast } from "react-toastify";
import axios from "axios";
import {BACKEND_DOMAIN} from "../../../../features/auth/authService";


export default function AddCharge() {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState(null);
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const [movementData, setMovementData] = useState([]);
    const [selectedUserIds, setSelectedUserIds] = useState([]);

    const [formData, setFormData] = useState({
        movement_id: "",
        costo: "", // Añadimos el costo
    });

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedUserIds(newSelectedRowKeys); // Actualiza los IDs seleccionados
    };
    const rowSelection = {
        selectedRowKeys: selectedUserIds,
        onChange: onSelectChange,
    };

    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: "Numero Cliente",
            dataIndex: "client_number",
            key: "client_number",
            width: "20%",
            ...getColumnSearchProps("client_number"),
        },
        {
            title: "Nombre",
            dataIndex: "name",
            key: "name",
            width: "20%",
            ...getColumnSearchProps("name"),
        },
        {
            title: "Rut Asociado",
            dataIndex: "rut",
            key: "rut",
            width: "20%",
            ...getColumnSearchProps("rut"),
        },
        {
            title: "Medidor",
            dataIndex: "meter",
            key: "meter",
            width: "20%",
            ...getColumnSearchProps("meter"),
        },
    ];


    useEffect(() => {
        const fetchMovimientos = async () => {
            const user = JSON.parse(localStorage.getItem("user"));
            const token = user ? user.access : null;

            if (!token) {
                toast.error("Token de autenticación no disponible");
                return;
            }

            try {
                const response = await axios.get(`${BACKEND_DOMAIN}/api/v1/movimiento/apr/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setMovementData(response.data);
            } catch (error) {
                console.error("Error al obtener los datos del movimiento:", error.response ? error.response.data : error.message);
                toast.error("Error al obtener los datos del movimiento");
            }
        };

        fetchMovimientos();
    }, []);


    useEffect(() => {
        const fetchMovimientos = async () => {
            // Obtener el token desde localStorage
            const user = JSON.parse(localStorage.getItem("user"));
            const token = user ? user.access : null;

            // Verificar que el token esté disponible
            if (!token) {
                toast.error("Token de autenticación no disponible");
                return;
            }

            try {
                const response = await axios.get(`${BACKEND_DOMAIN}/api/v1/movimiento/apr/`, {
                    headers: {
                        Authorization: `Bearer ${token}`, // Incluir el token en los headers
                    },
                });
                setMovementData(response.data); // Guardar la respuesta en el estado movementData
            } catch (error) {
                console.error("Error al obtener los datos del movimiento:", error.response ? error.response.data : error.message);
                toast.error("Error al obtener los datos del movimiento");
            }
        };

        fetchMovimientos();
    }, []);


    useEffect(() => {
        const fetchMedidores = async () => {
            try {
                const user = JSON.parse(localStorage.getItem("user"));
                const token = user?.access;

                if (!token) {
                    throw new Error("No se encontró el token en el localStorage");
                }

                const response = await axios.get(`${BACKEND_DOMAIN}/api/v1/getmedidores/apr/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const medidores = response.data;
                const mappedData = medidores.map((medidor, index) => ({
                    key: medidor.user.user_id,
                    name: `${medidor.user.first_name} ${medidor.user.last_name}`,
                    rut: formatRut(medidor.user.rut),
                    meter: medidor.meter_addr,
                    client_number: medidor.user.user_id,
                }));

                setData(mappedData);
                localStorage.setItem("medidoresData", JSON.stringify(mappedData));
            } catch (error) {
                console.error("Error fetching medidores:", error);
            }
        };

        fetchMedidores();
    }, []);


    const handleSave = async (e) => {
        e.preventDefault();
        console.log("formData", formData)
        if (selectedUserIds.length === 0) {
            toast.error("Debe seleccionar al menos un usuario.");
            return;
        }

        const user = JSON.parse(localStorage.getItem("user"));
        const token = user ? user.access : null;

        if (!token) {
            toast.error("Token de autenticación no disponible");
            return;
        }

        const requestBody = {
            user_ids: selectedUserIds,
            cargo_data: {
                costo: formData.costo,
                movimiento_fk: formData.movement_id,
            },
        };
        try {
            const response = await axios.post(
                `${BACKEND_DOMAIN}/api/v1/cargo/crear_masa/`,
                requestBody,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast.success("Cargo ingresado correctamente.");
            navigate("/menu_apr"); // Redirigir a la página principal después de guardar
        } catch (error) {
            console.error("Error al guardar el cargo:", error.response ? error.response.data : error.message);
            toast.error("Error al guardar el cargo.");
        }
    };

    const formatRut = (rut) => {
        // Convertir el rut a string para asegurar su manipulación como cadena
        const rutStr = String(rut).trim();

        // Obtener el dígito verificador y los dígitos del rut
        const rutDigits = rutStr.slice(0, -1);
        const dv = rutStr.slice(-1);

        // Formatear los dígitos con puntos
        let formattedRut = rutDigits.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        // Agregar el dígito verificador
        formattedRut = `${formattedRut}-${dv}`;

        return formattedRut;
    };


    return (
        <div className="menu-container">
            <div className="menu-content-container">
                <div className="top-gray-container small">
                    <h1 className="container-title">Ingresar cargo: </h1>
                </div>
                <div className="renegotiate-main-container">
                    <div className="renegotiate-form-container">
                        <div className='top-form-container gap-1'>
                            <div className='form-input-middle-container'>
                                <div className='payment-method-container'>
                                    <span className='input-title-text'>Tipo de movimiento</span>
                                    <select
                                        name="movement_id"
                                        value={formData.movement_id}
                                        onChange={handleChange}
                                        className="number-input"
                                    >
                                        <option value="">Seleccione una opción</option>
                                        {movementData.map((movimiento) => (
                                            <option key={movimiento.id} value={movimiento.id}>
                                                {movimiento.descripcion}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='down-payment-container'>
                                    <span className='input-title-text'>Precio Movimiento</span>
                                    <input
                                        type="number"
                                        className="number-input"
                                        name="costo"
                                        value={formData.costo}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='form-input-last-container'>
                                <div className="buttons-container">
                                    <button type="button" className="btn-cancel" onClick={() => navigate("/menu_apr")}>
                                        <img src={cancelIcon} className="cancel-image" />
                                        Cancelar
                                    </button>
                                    <button type="submit" className="btn-save" onClick={handleSave}>
                                        <img src={saveDisk} className="save-image" />
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='bottom-table-container'>
                        <Table rowSelection={rowSelection} columns={columns} dataSource={data} />
                    </div>
                </div>
            </div>
        </div>
    )
}
