import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import saveDisk from "../../../../images/save-disk.svg";
import cancelIcon from "../../../../images/cancel-icon.svg";
import {BACKEND_DOMAIN} from "../../../../features/auth/authService";


export default function PaymentMethodCreate() {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        id: "",
        descripcion: "",
        assigned_apr: null, // Para mantener el valor de assigned_apr
    });

    // Fetch assigned_apr when the component mounts
    useEffect(() => {
        const fetchAssignedApr = async () => {
            const user = JSON.parse(localStorage.getItem("user"));
            const token = user?.access;

            if (!token) {
                toast.error("Token de autenticación no disponible");
                return;
            }

            try {
                // Obtener el valor de `assigned_apr`
                const response = await axios.get(`${BACKEND_DOMAIN}/api/v1/user-detail/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const userData = response.data;
                setFormData((prev) => ({
                    ...prev,
                    assigned_apr: userData.assigned_apr !== undefined ? userData.assigned_apr : null,
                }));

                toast.success("Datos del usuario cargados correctamente");
            } catch (error) {
                console.error("Error al obtener detalles del usuario:", error.response ? error.response.data : error.message);
                toast.error("Error al obtener detalles del usuario");
            }
        };

        fetchAssignedApr();
    }, []);

    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Crear el objeto con los datos a enviar
        const dataToCreate = {
            id: formData.id,
            descripcion: formData.descripcion,
            assigned_apr: formData.assigned_apr, // Incluir assigned_apr que no se muestra
        };

        const user = JSON.parse(localStorage.getItem("user"));
        const token = user?.access;

        if (dataToCreate.descripcion && dataToCreate.id && token) {
            try {
                // Enviar los datos con POST para crear un nuevo medio de pago
                await axios.post(
                    `${BACKEND_DOMAIN}/api/v1/mediopago/create/`,
                    dataToCreate,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                toast.success("Método de pago creado con éxito");
                navigate("/menu_apr/payment_methods/"); // Redirigir a la lista después de guardar
            } catch (error) {
                console.error("Error al crear el medio de pago:", error.response?.data || error.message);
                toast.error("Error al crear el medio de pago");
            }
        } else {
            toast.error("Faltan datos para crear el medio de pago o falta el token");
        }
    };

    return (
        <div className="menu-container">
            <div className="menu-content-container">
                <div className="my-profile-container">
                    <div className="form-container">
                        <h1 className="form-title">Crear Nuevo Medio de Pago</h1>
                        <form className="edit-user-form" onSubmit={handleSubmit}>
                            <div className="input-container">
                                <label>Código de medio de pago</label>
                                <input
                                    type="text"
                                    name="id"
                                    value={formData.id}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="input-container">
                                <label>Descripción del medio de pago</label>
                                <input
                                    type="text"
                                    name="descripcion"
                                    value={formData.descripcion}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="buttons-container">
                                <button
                                    type="button"
                                    className="btn-cancel"
                                    onClick={() => navigate("/menu_apr")}
                                >
                                    <img src={cancelIcon} className="cancel-image" />
                                    Cancelar
                                </button>
                                <button type="submit" className="btn-save">
                                    <img src={saveDisk} className="save-image" />
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
