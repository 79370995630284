import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import saveDisk from "../../../../images/save-disk.svg";
import cancelIcon from "../../../../images/cancel-icon.svg";
import { Button, Input, message, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {BACKEND_DOMAIN} from "../../../../features/auth/authService";



export default function Renegotiate() {
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const [data, setData] = useState([]);
    const { userId, invoiceId } = useParams(); // Extraer el userId de la URL
    const [userInfo, setUserInfo] = useState({
        id: "",
        rut: "",
        first_name: "",
        last_name: "",
        second_last_name: "",
        email: "",
        birthday: "",
        boleta: "",
        cargos: [],
        cuota_convenio_pendiente: "",
        cargo_fijo_arranque: "",
        cargos_fijos_usuario: "",
		meter_addr:"",

    });

    // Estados para manejar los valores del formulario
    const [codigoConvenio, setCodigoConvenio] = useState(1);
    const [cantCuotas, setCantCuotas] = useState(3);
    const [fechaEmision, setFechaEmision] = useState("");
    const [montoPie, setMontoPie] = useState(0);
    const [convenioPagado, setConvenioPagado] = useState(false);


    const handleEditClick = (userId) => {
        // Redirigir al componente de edición pasando el ID del usuario
        navigate(`/menu_apr/editar-usuario/${userId}`);
    };

    const handleConsumptionsClick = (userId) => {
        navigate(`/menu_apr/consumptions/${userId}`);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };


    // Función para manejar el envío del formulario
    const handleSubmit = async (e) => {
        e.preventDefault();

        const convenioData = {
            codigo_convenio: codigoConvenio,
            cant_cuotas: cantCuotas,
            fecha_emision_convenio: `${fechaEmision}T15:00:00Z`,
            convenio_pagado: convenioPagado,
            monto_pie: montoPie,
            boleta_fk: invoiceId,
        };

        try {
            const response = await fetch(`${BACKEND_DOMAIN}/api/v1/convenio/create/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
                body: JSON.stringify(convenioData),
            });

            if (response.ok) {
                message.success("Convenio creado exitosamente");
                navigate("/menu_apr");
            } else {
                message.error("Error al crear el convenio");
            }
        } catch (error) {
            message.error("Error de red");
        }
    };


    useEffect(() => {
        const fetchDetailBoletas = async () => {
            try {
                const user = JSON.parse(localStorage.getItem("user"));
                const token = user?.access;

                if (!token) throw new Error("No se encontró el token en el localStorage");

                const response = await fetch(
                    `${BACKEND_DOMAIN}/api/v1/boleta/user/last-detail/${userId}/`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`
                        }
                    }
                );

                const result = await response.json();
                if (response.ok && result.usuario) {
                    setUserInfo({
                        id: result.usuario.id || "",
                        rut: result.usuario.rut || "",
                        first_name: result.usuario.first_name || "",
                        last_name: result.usuario.last_name || "",
                        second_last_name: result.usuario.second_last_name || "",
                        email: result.usuario.email || "",
                        address: result.usuario.address || "",
                        birthday: result.usuario.birthday || "",
                        boleta: result.boleta || "",
                        cargos: result.cargos || [],
                        cuota_convenio_pendiente: result.cuota_convenio_pendiente || 0,
                        cargo_fijo_arranque: result.cargo_fijo_arranque || "",
                        cargos_fijos_usuario: result.cargos_fijos_usuario || "",
						meter_addr: result.usuario.meter_addr || "",

                    });
                } else {
                    console.error("Error al obtener los detalles:", result);
                }

            } catch (error) {
                console.error("Error en la petición:", error.message);
            }
        };

        fetchDetailBoletas();
    }, [userId]);



    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: "N° Conv.",
            dataIndex: "agreement_number",
            key: "agreement_number",
            width: "10%",
            ...getColumnSearchProps("agreement_number"),
        },
        {
            title: "Fecha Conv.",
            dataIndex: "agreement_date",
            key: "agreement_date",
            width: "10%",
            ...getColumnSearchProps("agreement_date"),
            // render: (_, record) => (
            //     <Space size="middle">
            //         {record.state === "Activo" ? (
            //             <div className="active-state">Activo</div>
            //         ) : record.state === "Inactivo" ? (
            //             <div className="inactive-state">Inactivo</div>
            //         ) : record.state === "Revisar" ? (
            //             <div className="review-state">Revisión</div>
            //         ) : (
            //             ""
            //         )}
            //     </Space>
            // ),
        },
        {
            title: "Saldo pendiente",
            dataIndex: "outstanding_balance",
            key: "outstanding_balance",
            width: "10%",
            ...getColumnSearchProps("outstanding_balance"),
        },
        {
            title: "Pie",
            dataIndex: "down_payment",
            key: "down_payment",
            width: "10%",
            ...getColumnSearchProps("down_payment"),
        },
        {
            title: "N° Cuotas",
            dataIndex: "monthly_fee_number",
            key: "monthly_fee_number",
            width: "10%",
            ...getColumnSearchProps("monthly_fee_number"),
            sorter: (a, b) => a.address.length - b.address.length,
            sortDirections: ["descend", "ascend"],
        },
        {
            title: "Desde",
            dataIndex: "from_date",
            key: "from_date",
            width: "10%",
            ...getColumnSearchProps("from_date"),
        },
        {
            title: "Hasta",
            dataIndex: "to_date",
            key: "to_date",
            width: "10%",
            ...getColumnSearchProps("to_date"),
        },
        {
            title: "Acción",
            dataIndex: "edit",
            key: "edit",
            render: (_, record) => (
                <div className="actions-container">
                    <a className="active-link" onClick={() => handleEditClick(record.key)}>
                        Editar
                    </a>
                    <a className="active-link" onClick={() => handleConsumptionsClick(record.key)}>
                        Imprimir
                    </a>
                </div>
            ),
        },
    ];


    useEffect(() => {
        // Datos de ejemplo para el mockup
        const mockData = [
            {
                key: "1",
                agreement_number: "001",
                agreement_date: "2024-01-15",
                outstanding_balance: "$43,000",
                down_payment: "$5,000",
                monthly_fee_number: 6,
                from_date: "2024-02-01",
                to_date: "2024-07-01",
            },
            {
                key: "2",
                agreement_number: "002",
                agreement_date: "2024-01-20",
                outstanding_balance: "$21,000",
                down_payment: "$3,000",
                monthly_fee_number: 3,
                from_date: "2024-02-15",
                to_date: "2024-05-15",
            },
            {
                key: "3",
                agreement_number: "003",
                agreement_date: "2024-01-25",
                outstanding_balance: "$55,000",
                down_payment: "$10,000",
                monthly_fee_number: 5,
                from_date: "2024-03-01",
                to_date: "2024-07-01",
            },
            {
                key: "4",
                agreement_number: "003",
                agreement_date: "2024-01-25",
                outstanding_balance: "$55,000",
                down_payment: "$10,000",
                monthly_fee_number: 5,
                from_date: "2024-03-01",
                to_date: "2024-07-01",
            },
            {
                key: "5",
                agreement_number: "003",
                agreement_date: "2024-01-25",
                outstanding_balance: "$55,000",
                down_payment: "$10,000",
                monthly_fee_number: 5,
                from_date: "2024-03-01",
                to_date: "2024-07-01",
            },
        ];
        setData(mockData);
    }, []);


    return (
        <div className="menu-container">
            <div className="menu-content-container">
                <div className="top-gray-container small">
                    <div className="user-info-container">
                        <h3 className="info-text">Nombre: <span className="marked">{`${userInfo.first_name} ${userInfo.last_name}`}</span></h3>
                        <h3 className="info-text">N° Medidor: <span className="marked">{userInfo.meter_addr}</span></h3>
                        <h3 className="info-text">RUT: <span className="marked">{userInfo.rut}</span></h3>
                        <h3 className="info-text">Dirección: <span className="marked">{userInfo.address}</span></h3>
                    </div>
                </div>
                <div className="renegotiate-main-container">
                    <h1 className="form-title">Ingresar convenio de pago: </h1>
                    <div className="renegotiate-form-container">
                        <div className='top-form-container'>
                            <div className='form-input-first-container'>
                                <div className='debt-container'>
                                    <h3 className='debt-text'>Saldo pendiente:&nbsp;</h3>
                                    <div className='ammount-container'>
                                        <h3 className='debt-amount'>$43.000</h3>
                                    </div>
                                </div>
                                <div className='input-grid-container-c4-r4'>
                                    <div className='date input-container'>
                                        <span className='input-title-text'>Fecha de convenio</span>
                                        <input type="date" className="date-input"></input>
                                    </div>
                                    <div className='date input-container'>
                                        <span className='input-title-text'>Fecha primera cuota</span>
                                        <input type="date" className="date-input"></input>
                                    </div>
                                    <div className='select input-container'>
                                        <span className='input-title-text'>N° cuotas</span>
                                        <select name="pay-type" defaultValue={3} className="select-input">
                                            <option value="3">3</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="8">8</option>
                                            <option value="10">10</option>
                                            <option value="12">12</option>
                                        </select>
                                    </div>
                                    <div className='int input-container'>
                                        <span className='input-title-text'>Valor cuota</span>
                                        <input type="number" className="number-input"></input>
                                    </div>
                                </div>
                            </div>
                            <div className='form-input-middle-container'>
                                <div className='down-payment-container'>
                                    <span className='input-title-text'>Monto pie</span>
                                    <input type="number" className="number-input"></input>
                                </div>
                                <div className='payment-method-container'>
                                    <span className='input-title-text'>Forma de pago</span>
                                    <select name="pay-type" defaultValue={""} className="select-input">
                                        <option value="">Efectivo</option>
                                        <option value="">Cheque</option>
                                        <option value="">Transferencia</option>
                                        <option value="">Deposito cuenta Sec. Apr</option>
                                    </select>
                                </div>
                                <div className='voucher-number-container'>
                                    <span className='input-title-text'>N° de comp.</span>
                                    <input type="number" className="number-input"></input>
                                </div>
                                <div className='chek-number-container'>
                                    <span className='input-title-text'>N° de cheque</span>
                                    <input type="number" className="number-input"></input>

                                </div>
                            </div>
                            <div className='form-input-last-container'>
                                <div className="buttons-container">
                                    <button type="button" className="btn-cancel" onClick={() => navigate("/menu_apr")}>
                                        <img src={cancelIcon} className="cancel-image" />
                                        Cancelar
                                    </button>
                                    <button type="submit" className="btn-save">
                                        <img src={saveDisk} className="save-image" />
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='bottom-table-container'>
                        <Table columns={columns} dataSource={data} pagination={{ pageSize: 3 }} />
                    </div>
                </div>
            </div>
        </div>
    )
}
