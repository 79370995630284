import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import saveDisk from '../../../../images/save-disk.svg';
import cancelIcon from '../../../../images/cancel-icon.svg';
import {BACKEND_DOMAIN} from "../../../../features/auth/authService";

export default function SectorCreate() {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        sector_name: '', // Campo editable
        comuna_fk: 1,    // Comuna por defecto
    });

    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const user = JSON.parse(localStorage.getItem('user'));
        const token = user?.access;

        if (!token) {
            toast.error('Token de autenticación no disponible');
            return;
        }

        try {
            await axios.post(
                `${BACKEND_DOMAIN}/api/v1/sector/crear/`,
                formData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast.success('Sector creado con éxito');
            navigate('/menu_apr/sectors/');
        } catch (error) {
            console.error('Error al crear el sector:', error.response ? error.response.data : error.message);
            toast.error('Error al crear el sector');
        }
    };

    return (
        <div className="menu-container">
            <div className="menu-content-container">
                <div className="my-profile-container">
                    <div className="form-container">
                        <h1 className="form-title">Crear Nuevo Sector</h1>
                        <form className="edit-user-form" onSubmit={handleSubmit}>
                            <div className="input-container">
                                <label>Nombre del Sector</label>
                                <input
                                    type="text"
                                    name="sector_name"
                                    value={formData.sector_name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="buttons-container">
                                <button
                                    type="button"
                                    className="btn-cancel"
                                    onClick={() => navigate('/menu_apr')}
                                >
                                    <img src={cancelIcon} className="cancel-image" alt="Cancelar" />
                                    Cancelar
                                </button>
                                <button type="submit" className="btn-save">
                                    <img src={saveDisk} className="save-image" alt="Guardar" />
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
