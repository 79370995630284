import React, { useEffect, useState } from "react";
import { Table, Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import Highlighter from "react-highlight-words";
import { useNavigate } from "react-router-dom";
import plusIcon from "../../../images/plus-icon.svg";
import {BACKEND_DOMAIN} from "../../../features/auth/authService";


export default function Fares() {
	const [data, setData] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");
	const [error, setError] = useState(null);
	const navigate = useNavigate();

	const fetchFares = async () => {
		try {
			const user = JSON.parse(localStorage.getItem("user"));
			const token = user?.access;

			if (!token) {
				throw new Error("No se encontró el token en el localStorage");
			}

			const response = await axios.get(`${BACKEND_DOMAIN}/api/v1/tarifa/apr/`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			const fares = response.data;
			const mappedData = fares.map((fare) => ({
				key: fare.id,
				code: fare.codigo,
				fare_description: fare.descripcion,
				discount_percentage: `${fare.descuento}%`,
				cap_mount: `$${fare.monto_tope}`,
			}));

			setData(mappedData);
		} catch (error) {
			console.error("Error fetching fares:", error);
			setError(error.message);
		}
	};

	const handleEditClick = (fareId) => {
		// Redirigir al componente de edición pasando el ID del movimiento
		// navigate(`/menu_apr/editar-usuario/${userId}`);
		navigate(`/menu_apr/edit_fare/${fareId}`);
	};
	const handleAddClick = () => {
		navigate(`/menu_apr/create_fare/`);
	};

	const handleDeleteClick = (fareId) => {
		// navigate(`/menu_apr/consumptions/${userId}`);
	};

	useEffect(() => {
		fetchFares();
	}, []);

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					placeholder={`Buscar ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => confirm()}
					style={{ marginBottom: 8, display: "block" }}
				/>
				<Button
					type="primary"
					onClick={() => confirm()}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: "100%" }}
				>
					Buscar
				</Button>
				<Button
					onClick={() => clearFilters && clearFilters()}
					size="small"
					style={{ width: "100%", marginTop: 8 }}
				>
					Reiniciar
				</Button>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ""}
				/>
			) : (
				text
			),
	});

	const columns = [
		{
			title: "Código",
			dataIndex: "code",
			key: "code",
			...getColumnSearchProps("code"),
		},
		{
			title: "Descripción tarifa",
			dataIndex: "fare_description",
			key: "fare_description",
			...getColumnSearchProps("fare_description"),
		},
		{
			title: "Porcentaje descuento",
			dataIndex: "discount_percentage",
			key: "discount_percentage",
			...getColumnSearchProps("discount_percentage"),
		},
		{
			title: "Monto tope",
			dataIndex: "cap_mount",
			key: "cap_mount",
			...getColumnSearchProps("cap_mount"),
		},

		{
			title: "Acción",
			dataIndex: "edit",
			key: "edit",
			render: (_, record) => (
				<div className="actions-container">
					<a className="active-link" onClick={() => handleEditClick(record.key)}>
						Editar
					</a>
					<a className="review-link" onClick={() => handleDeleteClick(record.key)}>
						Eliminar
					</a>
				</div>
			),
		},
	];

	return (
		<div className="menu-container">
			<div className="menu-content-container">
				<div className="top-gray-container small">
					<h1 className="container-title">Tarifa</h1>
					<div className="add-button-container">
						<img className="plus-icon" src={plusIcon} />
						<a className="add-button" onClick={() => handleAddClick("")}>
							Añadir tarifa
						</a>
					</div>
				</div>
				<Table columns={columns} dataSource={data} />
			</div>
		</div>
	);
}
