import React from 'react'
import "./bidding.scss"
import logo from "./logo.svg"
import glass from "./glass.svg"
import imageSample1 from "./image-sample-1.svg"
import imageSample2 from "./image-sample-2.svg"
import imageSample3 from "./image-sample-3.svg"
import { useNavigate } from 'react-router-dom'


export default function Bidding() {

    const navigate = useNavigate();
    
    const handleLogoClick = () => {
		navigate(`/limari`);
	};
    const handleSearchClick = () => {
		navigate(`/limari/ofertas`);
	};

    return (
        <div className='bidding-overlap'>
            <div className='header-container'>
                <div className='logo-container' onClick={handleLogoClick}>
                    <img className='logo-img' src={logo} />
                </div>
                <div className='buttons-container'>
                    <div className='register-button'>
                        <h2>Registro</h2>
                    </div>
                    <div className='login-button'>
                        <h2>Iniciar Sesión</h2>
                    </div>
                </div>
            </div>
            <div className='backlink-container' onClick={handleLogoClick}>
                <h2 className='backlink-text'>Volver al inicio</h2>
            </div>
            <div className='body-container'>
                <div className='title-container'>
                    <h2 className='body-title'>Licitaciones Asociación gremial de APR’s</h2>
                </div>
                <div className='search-container' onClick={handleSearchClick}>
                    <h2 className='search-title'>¿Qué deseas licitar en la asociación? </h2>
                    <div className='input-box'>
                        <img className='search-glass' src={glass}/>
                    </div>
                    <div className='all-bidding-container' onClick={handleSearchClick}>
                        <button className='all-bidding-button'>Ver todas las licitaciones activas.</button>
                    </div>
                </div>
                <div className='body-info-container'>
                    <div className='card-container'>
                        <img className='card-image' src={imageSample1} />
                        <h3 className='card-text'>Lorem ipsum odor amet, consectetuer adipiscing elit. Nec diam varius dignissim ornare purus cursus congue. Dictum ullamcorper maecenas lacus class ullamcorper a netus. </h3>
                    </div>
                    <div className='card-container'>
                        <img className='card-image' src={imageSample2}/>
                        <h3 className='card-text'>Lorem ipsum odor amet, consectetuer adipiscing elit. Nec diam varius dignissim ornare purus cursus congue. Dictum ullamcorper maecenas lacus class ullamcorper a netus. </h3>
                    </div>
                    <div className='card-container'>
                        <img className='card-image' src={imageSample3}/>
                        <h3 className='card-text'>Lorem ipsum odor amet, consectetuer adipiscing elit. Nec diam varius dignissim ornare purus cursus congue. Dictum ullamcorper maecenas lacus class ullamcorper a netus. </h3>
                    </div>
                </div>
            </div>
            <div className='footer-container'>
                <h3 className='footer-text'>Footer</h3>
            </div>
        </div>
    )
}
