import React, { useEffect, useRef, useState } from "react";
import logo from "./logo.svg"
import glass from "./glass.svg"
import fileSearch from "./file-search.svg"
import { Button, Input, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useNavigate } from "react-router-dom";


export default function OfferFiles() {
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const navigate = useNavigate();

    const handleLogoClick = () => {
        // Redirigir al componente de edición pasando el ID del movimiento
        // navigate(`/menu_apr/editar-usuario/${userId}`);
        navigate(`/limari`);
    };
    const handleDownloadClick = () => {
        // Redirigir al componente de edición pasando el ID del movimiento
        // navigate(`/menu_apr/editar-usuario/${userId}`);
        // navigate(`/limari`);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: "Anexo",
            dataIndex: "anexo",
            key: "anexo",
            ...getColumnSearchProps("anexo"),
        },
        {
            title: "Tipo",
            dataIndex: "type",
            key: "type",
            ...getColumnSearchProps("type"),
        },
        {
            title: "Descripcion",
            dataIndex: "description",
            key: "description",
            ...getColumnSearchProps("description"),
        },
        {
            title: "Tamaño",
            dataIndex: "size",
            key: "size",
            ...getColumnSearchProps("size"),
        },
        {
            title: "Fecha de subida",
            dataIndex: "upload_date",
            key: "upload_date",
            ...getColumnSearchProps("upload_date"),
        },
        {
            title: "Acción",
            dataIndex: "action",
            key: "action",
            render: (_, record) => (
				<div className="actions-container">
					<a className="active-link" onClick={() => handleDownloadClick(record.key)}>
						Descargar
					</a>
				</div>
			),
        },
    ];

    const data = [
        {
            key: "1",
            anexo: "Contrato_001.pdf",
            type: "PDF",
            description: "Contrato principal del cliente",
            size: "1.2 MB",
            upload_date: "2025-01-01",
        },
        {
            key: "2",
            anexo: "Anexo_A.docx",
            type: "Word",
            description: "Anexo A con especificaciones técnicas",
            size: "2.5 MB",
            upload_date: "2025-01-05",
        },
        {
            key: "3",
            anexo: "Imagen_Proyecto.jpg",
            type: "Imagen",
            description: "Vista aérea del proyecto",
            size: "3.8 MB",
            upload_date: "2025-01-10",
        },
        {
            key: "4",
            anexo: "Lista_Precios.xlsx",
            type: "Excel",
            description: "Lista de precios actualizada",
            size: "1.1 MB",
            upload_date: "2025-01-12",
        },
        {
            key: "5",
            anexo: "Presentacion.pptx",
            type: "PowerPoint",
            description: "Presentación de oferta",
            size: "5.4 MB",
            upload_date: "2025-01-15",
        },
    ];

    return (
        <div className='bidding-overlap'>
            <div className='header-container'>
                <div className='logo-container' onClick={handleLogoClick}>
                    <img className='logo-img' src={logo} />
                </div>
                <div className='buttons-container'>
                    <div className='register-button'>
                        <h2>Registro</h2>
                    </div>
                    <div className='login-button'>
                        <h2>Iniciar Sesión</h2>
                    </div>
                </div>
            </div>
            <div className='backlink-container' onClick={handleLogoClick}>
                <h2 className='backlink-text'>Volver al inicio</h2>
            </div>
            <div className='offer-files'>
                <Table columns={columns} dataSource={data} />
            </div>
            <div className='footer-container'>
                <h3 className='footer-text'>Footer</h3>
            </div>
        </div>
    )
}
