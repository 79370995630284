import React from 'react'
import logo from "./logo.svg"
import glass from "./glass.svg"
import fileSearch from "./file-search.svg"
import { useNavigate } from 'react-router-dom';

export default function OfferDetail() {

    const navigate = useNavigate();

    const handleLogoClick = () => {
        // Redirigir al componente de edición pasando el ID del movimiento
        // navigate(`/menu_apr/editar-usuario/${userId}`);
        navigate(`/limari`);
    };

    const handleFilesClick = () => {
		navigate(`/limari/ofertas/1/files`);
	};

    return (
        <div className='bidding-overlap'>
            <div className='header-container'>
                <div className='logo-container' onClick={handleLogoClick}>
                    <img className='logo-img' src={logo} />
                </div>

                <div className='buttons-container'>
                    <div className='register-button'>
                        <h2>Registro</h2>
                    </div>
                    <div className='login-button'>
                        <h2>Iniciar Sesión</h2>
                    </div>
                </div>
            </div>
            <div className='backlink-container' onClick={handleLogoClick}>
                <h2 className='backlink-text'>Volver al inicio</h2>
            </div>
            <div className='offer-detail-container'>
                <div className='detail-header'>
                    <h3 className='code-text'>Codigo de licitacion: <span>a3dcb4d229de6fde0</span> </h3>
                    <div className='state-info'>
                        <h2 className='state-title'>Estado:</h2>
                        <div className='state-content'>
                            <h2 className='state-text'>Activo publicada</h2>
                        </div>
                    </div>
                </div>

                <h2 className='info-text'>Responsable: <span>Dirección de APR el Coipo</span></h2>
                <h2 className='info-text'>Fecha cierre: <span>10/01/2025 (faltan 116 días para el cierre)</span></h2>
                <div className='attached-files-button-container' onClick={handleFilesClick}>
                    <img className='search-files-icon' src={fileSearch} />
                    <h3>Ver Archivos adjuntos</h3>
                </div>
                <h2 className='info-text'>Producto o servicio solicitado:<span>Transporte de personal APR - 1 unidad</span></h2>

                <h2 className='category-title'>Caracteristicas:</h2>
                <h2 className='info-text'>Nombre de la licitación: Traslado de personal APR</h2>
                <h2 className='info-text'>Estado: Publicada</h2>
                <h2 className='info-text'>Descripción: Proveer servicio de transporte desde Punto A a punto B en la fecha 02/02/2023</h2>

                <h2 className='category-title'>Organismo demandante:</h2>
                <h2 className='info-text'>Razón social: APR El Coipo</h2>
                <h2 className='info-text'>Rut: 9.876.543-1</h2>
                <h2 className='info-text'>Dirección: Tanto Oriente 232.</h2>

                <h2 className='category-title'>Etapas:</h2>
                <h2 className='info-text'>Fecha de Publicación: 05-09-2024 13:14:00</h2>
                <h2 className='info-text'>Fecha de acto de apertura técnica: 05-09-2024 13:14:00</h2>
                <h2 className='info-text'>Fecha de Adjudicación: 05-09-2024 13:14:00</h2>
                <h2 className='info-text'>Fecha estimada de firma de contrato: 05-09-2024 13:14:00</h2>

                <h2 className='category-title'>Montos y duración del contrato:</h2>
                <h2 className='info-text'>Fuente de financiamiento: Dirección APR el Coipo</h2>
                <h2 className='info-text'>Tiempo del contrato: 15 Días</h2>
                <h2 className='info-text'>Plazos de pago: 30 días contra la recepción conforme de la factura</h2>
                <h2 className='info-text'>Opciones de pago: Cheque</h2>
                <h2 className='info-text'>Nombre de responsable de contrato: NELSON BALTIMORE WASHINGTON</h2>
                <h2 className='info-text'>e-mail de responsable de contrato: nelson.baltimore@washington.cl</h2>
                <h2 className='info-text'>Teléfono de responsable del contrato: 56-71-2612259</h2>
            </div>
            <div className='footer-container'>
                <h3 className='footer-text'>Footer</h3>
            </div>
        </div>
    )
}
