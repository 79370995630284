import { Button, Input, Space, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useNavigate } from "react-router-dom";
import plusIcon from "../../../images/plus-icon.svg";
import spreadsheetIcon from "../../../images/spreadsheet-white.svg";
import axios from "axios";
import {BACKEND_DOMAIN} from "../../../features/auth/authService";

export default function ClientsMovements() {
	const [data, setData] = useState([]);
	const searchInput = useRef(null);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");
	const navigate = useNavigate();

	const handleChargesClick = (userId) => {
		// Redirigir al componente de edición pasando el ID del usuario
		navigate(`/menu_apr/user-charges/${userId}/`);
	};
	const handleAddClick = () => {
		// Redirigir al componente de edición pasando el ID del usuario
		// navigate(`/menu_apr/editar-usuario/${userId}`);
		navigate(`/menu_apr/add-charge/`);
	};
	const handleSpreadsheetClick = (userId) => {
		// Redirigir al componente de edición pasando el ID del usuario
		// navigate(`/menu_apr/editar-usuario/${userId}`);
		navigate(`/menu_apr/requirements-spreadsheets/`);

	};

	const handleMonthlyLecturesClick = (userId) => {
		// navigate(`/menu_apr/consumptions/${userId}`);
		navigate(`/menu_apr/monthly-lectures/${userId}`);

	};



	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
	};

	const formatRut = (rut) => {
		const rutStr = String(rut).trim();
		const rutDigits = rutStr.slice(0, -1);
		const dv = rutStr.slice(-1);
		let formattedRut = rutDigits.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
		formattedRut = `${formattedRut}-${dv}`;
		return formattedRut;
	};

	useEffect(() => {
		const fetchMedidores = async () => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				const token = user?.access;

				if (!token) {
					throw new Error("No se encontró el token en el localStorage");
				}

				const response = await axios.get(`${BACKEND_DOMAIN}/api/v1/getmedidores/apr/`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				const medidores = response.data;

				// Mapeo de los datos
				const mappedData = medidores.map((medidor) => ({
					key: medidor.user.user_id,
					client_number: medidor.id, // Número de cliente
					watermeter_number: medidor.meter_addr, // Número de medidor
					name: `${medidor.user.first_name} ${medidor.user.last_name}`,
					state: medidor.consumo.valve_status === "Opened" ? "Activo" : "Inactivo",
					rut: formatRut(medidor.user.rut),
					email: medidor.user.email,
					address: medidor.user.address,
					sector: medidor.user.sector,
					edit: "Editar",
				}));

				setData(mappedData);
			} catch (error) {
				console.error("Error fetching medidores:", error);
			}
		};


		fetchMedidores();
	}, []);

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					ref={searchInput}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ marginBottom: 8, display: "block" }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}
					>
						Reset
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm({ closeDropdown: false });
							setSearchText(selectedKeys[0]);
							setSearchedColumn(dataIndex);
						}}
					>
						Filter
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm();
						}}
					>
						close
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ""}
				/>
			) : (
				text
			),
	});

	const columns = [
		{
			title: "N° Cliente",
			dataIndex: "client_number",
			key: "client_number",
			width: "10%",
			...getColumnSearchProps("client_number"),
		},
		{
			title: "N° Medidor",
			dataIndex: "watermeter_number",
			key: "watermeter_number",
			width: "20%",
			...getColumnSearchProps("watermeter_number"),
		},
		{
			title: "Rut",
			dataIndex: "rut",
			key: "rut",
			width: "15%",
			...getColumnSearchProps("rut"),
			sorter: (a, b) => a.rut.length - b.rut.length,
			sortDirections: ["descend", "ascend"],
		},
		{
			title: "Nombre",
			dataIndex: "name",
			key: "name",
			width: "15%",
			...getColumnSearchProps("name"),
		},
		{
			title: "Estado",
			dataIndex: "state",
			key: "state",
			width: "10%",
			...getColumnSearchProps("state"),
			render: (_, record) => (
				<Space size="middle">
					{record.state === "Activo" ? (
						<div className="active-state">Activo</div>
					) : record.state === "Inactivo" ? (
						<div className="inactive-state">Inactivo</div>
					) : record.state === "Revisar" ? (
						<div className="review-state">Revisión</div>
					) : (
						""
					)}
				</Space>
			),
		},
		{
			title: "Acción",
			dataIndex: "edit",
			key: "edit",
			render: (_, record) => (
				<div className="actions-container">
					<a className="active-link" onClick={() => handleChargesClick(record.key)}>
						Cargos
					</a>
					<a className="active-link" onClick={() => handleMonthlyLecturesClick(record.key)}>
						Consumos
					</a>
				</div>
			),
		},
	];

	return (
		<div className="menu-container">
			<div className="menu-content-container">
				<div className="top-gray-container small">
					<h1 className="container-title">Movimientos clientes</h1>
					<div className="buttons-container">
						{/* <div className="add-button-container">
							<img className="plus-icon" src={spreadsheetIcon} />
							<a className="add-button" onClick={() => handleSpreadsheetClick("")}>
								Obtener planilla movimientos mes
							</a>
						</div> */}
						<div className="add-button-container">
							<img className="plus-icon" src={plusIcon} />
							<a className="add-button" onClick={() => handleAddClick("")}>
								Añadir Cargos en grupo
							</a>
						</div>
					</div>
				</div>
				<Table columns={columns} dataSource={data} />
			</div>
		</div>
	);
}
