import React from 'react'
import logo from "./logo.svg"
import glass from "./glass.svg"
import { useNavigate } from 'react-router-dom';

export default function Offers() {

    const navigate = useNavigate();
    
    const handleLogoClick = () => {
        // Redirigir al componente de edición pasando el ID del movimiento
        // navigate(`/menu_apr/editar-usuario/${userId}`);
        navigate(`/limari`);
    };

    const handleDetailsClick = () => {
		navigate(`/limari/ofertas/1`);
	};

    return (
        <div className='bidding-overlap'>
            <div className='header-container'>
                <div className='logo-container' onClick={handleLogoClick}>
                    <img className='logo-img' src={logo} />
                </div>
                <div className='buttons-container'>
                    <div className='register-button'>
                        <h2>Registro</h2>
                    </div>
                    <div className='login-button'>
                        <h2>Iniciar Sesión</h2>
                    </div>
                </div>
            </div>
            <div className='backlink-container' onClick={handleLogoClick}>
                <h2 className='backlink-text'>Volver al inicio</h2>
            </div>
            <div className='offer-body-container'>
                <div className='filter-container'>
                    <h2 className='filter-title'>Filtros</h2>
                    <div className='filter-form'>
                        <div className='input-container'>
                            <h3 className='input-title'>Sectores</h3>
                            <select for="sector-select" className='sector-selector'>
                                <option>
                                    Escoge un sector
                                </option>
                            </select>
                        </div>
                        <div className='input-container'>
                            <h3 className='input-title'>Presupuesto</h3>
                            <div className='checkbox-container'>
                                <input type="checkbox" className='checkbox-box'/>
                                <h3 className='checkbox-text'>Publicado</h3>
                            </div>
                            <div className='checkbox-container'>
                                <input type="checkbox" className='checkbox-box'/>
                                <h3 className='checkbox-text'>No publicado</h3>
                            </div>
                        </div>
                        <div className='input-container'>
                            <h3 className='input-title'>Desde</h3>
                            <input type='date' className='date-box'></input>
                        </div>
                        <div className='input-container'>
                            <h3 className='input-title'>Hasta</h3>
                            <input type='date' className='date-box'></input>
                        </div>
                        <div className='input-container'>
                            <h3 className='input-title'>Estados</h3>
                            <select for="sector-select" className='sector-selector'>
                                <option>
                                    Todos los estados
                                </option>
                                <option>
                                    Activo
                                </option>
                                <option>
                                    Inactivo
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='main-container'>
                    <div className='search-container'>
                        <h2 className='search-title'>¿Qué deseas licitar en la asociación? </h2>
                        <div className='input-box'>
                            <img className='search-glass' src={glass} />
                        </div>
                    </div>
                    <div className='main-container-title'>
                        <h3 className='container-title'>Se han encontrado mas de 303 resultados para la búsqueda.</h3>
                    </div>
                    <div className='card-container'>
                        <div className='info-container'>
                            <h3 className='code-text'>Codigo de licitacion: <span>a3dcb4d229de6fde0</span> </h3>
                            <h2 className='info-text'>Servicio de transporte para lorem ipsum </h2>
                            <h2 className='info-text'>Fecha publicación: <span>10/01/2025</span></h2>
                            <h2 className='info-text'>Fecha cierre: <span>10/01/2025</span></h2>
                        </div>
                        <div className='end-container'>
                            <div className='state-info'>
                                <h2 className='state-title'>Estado:</h2>
                                <div className='state-content'>
                                    <h2 className='state-text'>Activo publicada</h2>
                                </div>
                            </div>
                            <h2 className='owner-text'>Directiva apr El coipo</h2>  
                            <button onClick={handleDetailsClick} className='detail-button'>Detalles</button>                             
                        </div>
                    </div>
                    <div className='card-container'>
                        <div className='info-container'>
                            <h3 className='code-text'>Codigo de licitacion: <span>a3dcb4d229de6fde0</span> </h3>
                            <h2 className='info-text'>Servicio de transporte para lorem ipsum </h2>
                            <h2 className='info-text'>Fecha publicación: <span>10/01/2025</span></h2>
                            <h2 className='info-text'>Fecha cierre: <span>10/01/2025</span></h2>
                        </div>
                        <div className='end-container'>
                            <div className='state-info'>
                                <h2 className='state-title'>Estado:</h2>
                                <div className='state-content'>
                                    <h2 className='state-text'>Activo publicada</h2>
                                </div>
                            </div>
                            <h2 className='owner-text'>Directiva apr El coipo</h2>  
                            <button onClick={handleDetailsClick} className='detail-button'>Detalles</button>                             
                        </div>
                    </div>
                    <div className='card-container'>
                        <div className='info-container'>
                            <h3 className='code-text'>Codigo de licitacion: <span>a3dcb4d229de6fde0</span> </h3>
                            <h2 className='info-text'>Servicio de transporte para lorem ipsum </h2>
                            <h2 className='info-text'>Fecha publicación: <span>10/01/2025</span></h2>
                            <h2 className='info-text'>Fecha cierre: <span>10/01/2025</span></h2>
                        </div>
                        <div className='end-container'>
                            <div className='state-info'>
                                <h2 className='state-title'>Estado:</h2>
                                <div className='state-content'>
                                    <h2 className='state-text'>Activo publicada</h2>
                                </div>
                            </div>
                            <h2 className='owner-text'>Directiva apr El coipo</h2>  
                            <button onClick={handleDetailsClick} className='detail-button'>Detalles</button>                             
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-container'>
                <h3 className='footer-text'>Footer</h3>
            </div>
        </div>
    )
}
