import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import saveDisk from "../../../../images/save-disk.svg";
import cancelIcon from "../../../../images/cancel-icon.svg";
import {BACKEND_DOMAIN} from "../../../../features/auth/authService";


export default function FareEdit() {
	const navigate = useNavigate();
	const { fareId } = useParams(); // Extraer fareId desde la URL
	const [fareInfo, setFareInfo] = useState(null);

	const [formData, setFormData] = useState({
		codigo: "",
		descripcion: "",
		descuento: "",
		monto_tope: "",
		aplica: "",
	});

	useEffect(() => {
		const fetchFareDetails = async () => {
			const user = JSON.parse(localStorage.getItem("user"));
			const token = user?.access;

			if (!token) {
				toast.error("Token de autenticación no disponible");
				return;
			}

			try {
				const response = await axios.get(`${BACKEND_DOMAIN}/api/v1/tarifas/${fareId}`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				setFareInfo(response.data);
			} catch (error) {
				console.error("Error fetching fare details:", error);
				toast.error("Error al obtener los datos de la tarifa");
			}
		};

		fetchFareDetails();
	}, [fareId]);

	useEffect(() => {
		if (fareInfo) {
			setFormData({
				codigo: fareInfo.codigo || "",
				descripcion: fareInfo.descripcion || "",
				descuento: fareInfo.descuento !== undefined ? fareInfo.descuento : "",
				monto_tope: fareInfo.monto_tope !== undefined ? fareInfo.monto_tope : "",
				aplica: fareInfo.aplica || "",
			});
		}
	}, [fareInfo]);

	const handleChange = (e) => {
		setFormData((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const dataToUpdate = {};
		Object.keys(formData).forEach((key) => {
			const value = formData[key];
			if (typeof value === "string" && value.trim()) {
				dataToUpdate[key] = value;
			} else if (value) {
				dataToUpdate[key] = value;
			}
		});

		const user = JSON.parse(localStorage.getItem("user"));
		const token = user?.access;

		if (Object.keys(dataToUpdate).length > 0 && token) {
			try {
				await axios.put(`${BACKEND_DOMAIN}/api/v1/tarifa/edit/${fareId}/`, dataToUpdate, {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});

				toast.success("Cambios guardados con éxito");
				navigate("/menu_apr/fares");
			} catch (error) {
				console.error("Error al actualizar los datos:", error.response?.data || error.message);
				toast.error("Error al actualizar los datos");
			}
		} else {
			toast.error("No hay datos para actualizar o falta el token");
		}
	};

	return (
		<div className="menu-container">
			<div className="menu-content-container">
				<div className="my-profile-container">
					<div className="form-container">
						<h1 className="form-title">Editar Tarifa</h1>
						<form className="edit-user-form" onSubmit={handleSubmit}>
							<div className="input-container">
								<label>Código tarifa</label>
								<input
									type="text"
									name="codigo"
									value={formData.codigo}
									onChange={handleChange}
								/>
							</div>
							<div className="input-container">
								<label>Descripción de tarifa</label>
								<input
									type="text"
									name="descripcion"
									value={formData.descripcion}
									onChange={handleChange}
								/>
							</div>
							<div className="input-container">
								<label>Descuento (%)</label>
								<input
									type="number"
									name="descuento"
									value={formData.descuento}
									onChange={handleChange}
								/>
							</div>
							<div className="input-container">
								<label>Monto máximo de descuento</label>
								<input
									type="number"
									name="monto_tope"
									value={formData.monto_tope}
									onChange={handleChange}
								/>
							</div>
							<div className="input-container">
								<label>Aplicado a:</label>
								<select
									name="aplica"
									value={formData.aplica}
									onChange={handleChange}
								>
									<option value="">Seleccione una opción</option>
									<option value="Consumo">Consumo</option>
									<option value="Total">Total boleta</option>
								</select>
							</div>
							<div className="buttons-container">
								<button
									type="button"
									className="btn-cancel"
									onClick={() => navigate("/menu_apr/tarifas")}
								>
									<img src={cancelIcon} className="cancel-image" />
									Cancelar
								</button>
								<button type="submit" className="btn-save">
									<img src={saveDisk} className="save-image" />
									Guardar
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}
