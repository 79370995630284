import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import saveDisk from "../../../../images/save-disk.svg";
import cancelIcon from "../../../../images/cancel-icon.svg";
import {BACKEND_DOMAIN} from "../../../../features/auth/authService";


export default function ClientStateCreate() {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        descripcion: "",
        assigned_apr: null,
        calculation_process: "",
    });

    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const user = JSON.parse(localStorage.getItem("user"));
        const token = user?.access;

        if (!token) {
            toast.error("Token de autenticación no disponible");
            return;
        }

        let updatedFormData = { ...formData }; // Clonamos el estado actual

        try {
            // Obtener el valor de `assigned_apr`
            const response = await axios.get(`${BACKEND_DOMAIN}/api/v1/user-detail/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const userData = response.data;
            updatedFormData = {
                ...updatedFormData,
                assigned_apr: userData.assigned_apr !== undefined ? userData.assigned_apr : null,
            };

            toast.success("Datos del usuario cargados correctamente");
        } catch (error) {
            console.error("Error al obtener detalles del usuario:", error.response ? error.response.data : error.message);
            toast.error("Error al obtener detalles del usuario");
            return; // Detenemos la ejecución si hay un error en la solicitud GET
        }

        const dataToCreate = {
            descripcion: updatedFormData.descripcion,
            assigned_apr: updatedFormData.assigned_apr,
            calculation_process: updatedFormData.calculation_process, // Incluir el proceso de cálculo
        };

        try {
            const response = await axios.post(
                `${BACKEND_DOMAIN}/api/v1/estadocliente/create/`,
                dataToCreate,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success("Estado de cliente creado con éxito");
            navigate("/menu_apr/clients_states");
        } catch (error) {
            console.error("Error al crear el estado de cliente:", error.response ? error.response.data : error.message);
            toast.error("Error al crear el estado de cliente");
        }
    };

    return (
        <div className="menu-container">
            <div className="menu-content-container">
                <div className="my-profile-container">
                    <div className="form-container">
                        <h1 className="form-title">Crear Nuevo Estado de Cliente</h1>
                        <form className="edit-user-form" onSubmit={handleSubmit}>
                            <div className="input-container">
                                <label>Descripción del estado</label>
                                <input
                                    type="text"
                                    name="descripcion"
                                    value={formData.descripcion}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="input-container">
                                <label>Proceso de cálculo:</label>
                                <select
                                    name="calculation_process"
                                    value={formData.calculation_process}
                                    onChange={handleChange}
                                >
                                    <option value="">Seleccione un proceso</option>
                                    <option value="yes">Sí</option>
                                    <option value="no">No</option>
                                </select>
                            </div>
                            <div className="buttons-container">
                                <button
                                    type="button"
                                    className="btn-cancel"
                                    onClick={() => navigate("/menu_apr")}
                                >
                                    <img src={cancelIcon} className="cancel-image" />
                                    Cancelar
                                </button>
                                <button type="submit" className="btn-save">
                                    <img src={saveDisk} className="save-image" />
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
