import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import saveDisk from "../../../../images/save-disk.svg";
import cancelIcon from "../../../../images/cancel-icon.svg";
import {BACKEND_DOMAIN} from "../../../../features/auth/authService";


export default function FareCreate() {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        codigo: "",
        descripcion: "",
        descuento: "",
        monto_tope: "",
        aplica: "",
    });

    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const user = JSON.parse(localStorage.getItem("user"));
        const token = user?.access;

        if (!token) {
            toast.error("Token de autenticación no disponible");
            return;
        }

        try {
            await axios.post(
                `${BACKEND_DOMAIN}/api/v1/tarifa/create/`,
                formData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast.success("Tarifa creada con éxito");
            navigate("/menu_apr/fares");
        } catch (error) {
            console.error("Error al crear la tarifa:", error.response ? error.response.data : error.message);
            toast.error("Error al crear la tarifa");
        }
    };

    return (
        <div className="menu-container">
            <div className="menu-content-container">
                <div className="my-profile-container">
                    <div className="form-container">
                        <h1 className="form-title">Crear Nueva Tarifa</h1>
                        <form className="edit-user-form" onSubmit={handleSubmit}>
                            <div className="input-container">
                                <label>Código</label>
                                <input
                                    type="text"
                                    name="codigo"
                                    value={formData.codigo}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="input-container">
                                <label>Descripción</label>
                                <input
                                    type="text"
                                    name="descripcion"
                                    value={formData.descripcion}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="input-container">
                                <label>Descuento (%)</label>
                                <input
                                    type="number"
                                    step="0.01"
                                    name="descuento"
                                    value={formData.descuento}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="input-container">
                                <label>Monto Tope</label>
                                <input
                                    type="number"
                                    step="0.01"
                                    name="monto_tope"
                                    value={formData.monto_tope}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="input-container">
                                <label>Aplicado a:</label>
                                <select
                                    name="aplica"
                                    value={formData.aplica}
                                    onChange={handleChange}
                                >
                                    <option value="">Seleccione una opción</option>
                                    <option value="Consumo">Consumo</option>
                                    <option value="Total">Total boleta</option>
                                </select>
                            </div>
                            <div className="buttons-container">
                                <button
                                    type="button"
                                    className="btn-cancel"
                                    onClick={() => navigate("/menu_apr")}
                                >
                                    <img src={cancelIcon} className="cancel-image" />
                                    Cancelar
                                </button>
                                <button type="submit" className="btn-save">
                                    <img src={saveDisk} className="save-image" />
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
