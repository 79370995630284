import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import saveDisk from '../../../../images/save-disk.svg';
import cancelIcon from '../../../../images/cancel-icon.svg';
import {BACKEND_DOMAIN} from "../../../../features/auth/authService";

export default function WatermeterCreate() {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        meter_addr: '',          // Campo editable
        solicitud_cerrar: false, // Valor por defecto (no visible en el formulario)
        is_active: true,         // Campo editable (select)
        origen: '',              // Campo editable (select)
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const user = JSON.parse(localStorage.getItem('user'));
        const token = user?.access;

        if (!token) {
            toast.error('Token de autenticación no disponible');
            return;
        }

        try {
            await axios.post(
                `${BACKEND_DOMAIN}/api/v1/crear-medidor/`,
                formData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast.success('Medidor creado con éxito');
            navigate('/menu_apr/watermeters_list/');
        } catch (error) {
            console.error('Error al crear el medidor:', error.response ? error.response.data : error.message);
            toast.error('Error al crear el medidor');
        }
    };

    return (
        <div className="menu-container">
            <div className="menu-content-container">
                <div className="my-profile-container">
                    <div className="form-container">
                        <h1 className="form-title">Crear Nuevo Medidor</h1>
                        <form className="edit-user-form" onSubmit={handleSubmit}>
                            <div className="input-container">
                                <label>Numero del Medidor</label>
                                <input
                                    type="number"
                                    name="meter_addr"
                                    value={formData.meter_addr}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="input-container">
                                <label>Origen:</label>
                                <select
                                    name="origen"
                                    value={formData.origen}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Seleccione una opción</option>
                                    <option value="lora">Lora</option>
                                    <option value="lorawan">LoraWan</option>
                                    <option value="generico">Generico</option>
                                </select>
                            </div>
                            <div className="input-container">
                                <label>Estado Activo</label>
                                <select
                                    name="is_active"
                                    value={formData.is_active}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value={true}>Activo</option>
                                    <option value={false}>Inactivo</option>
                                </select>
                            </div>
                            <div className="buttons-container">
                                <button
                                    type="button"
                                    className="btn-cancel"
                                    onClick={() => navigate('/menu_apr')}
                                >
                                    <img src={cancelIcon} className="cancel-image" alt="Cancelar" />
                                    Cancelar
                                </button>
                                <button type="submit" className="btn-save">
                                    <img src={saveDisk} className="save-image" alt="Guardar" />
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
