import { Button, Input, Space, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useNavigate } from "react-router-dom";
import blueExcel from "../../../images/excel-icon-blue.svg"
import bluePlus from "../../../images/plus-icon-blue.svg"
import blueDisk from "../../../images/save-disk-blue.svg"
import blueCheck from "../../../images/check-circle-svgrepo-com.svg"
import axios from "axios";
import {BACKEND_DOMAIN} from "../../../features/auth/authService";


export default function ReadingEntries() {
	const [data, setData] = useState([]);
	const [fileData, setFileData] = useState([]);

	const searchInput = useRef(null);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");
	const navigate = useNavigate();


	const [completedButtons, setCompletedButtons] = useState({
		export: false,
		upload: false,
		save: false,
	});

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					ref={searchInput}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ marginBottom: 8, display: "block" }}
				/>
				<Space>
					<Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
						Search
					</Button>
					<Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
						Reset
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm({ closeDropdown: false });
							setSearchText(selectedKeys[0]);
							setSearchedColumn(dataIndex);
						}}>
						Filter
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm();
						}}>
						close
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />,
		onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ""}
				/>
			) : (
				text
			),
	});

	const columns = [
		{
			title: "N° Cliente",
			dataIndex: "client_number",
			key: "client_number",
			width: "10%",
			...getColumnSearchProps("client_number"),
		},
		{
			title: "N° Medidor",
			dataIndex: "watermeter_number",
			key: "watermeter_number",
			width: "20%",
			...getColumnSearchProps("watermeter_number"),
		},
		{
			title: "Nombre",
			dataIndex: "name",
			key: "name",
			width: "15%",
			...getColumnSearchProps("name"),
		},
		{
			title: "Lect. Anterior",
			dataIndex: "pre",
			key: "previous_lecture",
			width: "25%",
			...getColumnSearchProps("previous_lecture"),
			sorter: (a, b) => a.previous_lecture.length - b.previous_lecture.length,
			sortDirections: ["descend", "ascend"],
		},
		{
			title: "Lect. Actual",
			dataIndex: "actual_lecture",
			key: "actual_lecture",
			width: "15%",
			...getColumnSearchProps("actual_lecture"),
		},
		{
			title: "Sin Lectura",
			dataIndex: "no_lecture",
			key: "no_lecture",
			width: "15%",
			...getColumnSearchProps("no_lecture"),
		},
		{
			title: "Agregar lect. promedio",
			dataIndex: "add_default",
			key: "add_default",
			width: "15%",
			...getColumnSearchProps("add_default"),
		},
		{
			title: "Consumo",
			dataIndex: "consumption",
			key: "consumption",
			width: "15%",
			...getColumnSearchProps("consumption"),
		},
	];


	const handleExport = async () => {
		try {
			const url = `${BACKEND_DOMAIN}/api/v1/generar-excel-lecturas/`;
			const user = JSON.parse(localStorage.getItem("user"));
			const token = user?.access;

			if (!token) {
				throw new Error("No se encontró el token en el localStorage");
			}

			const response = await axios.get(url, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				responseType: "blob",
			});

			const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = urlBlob;
			link.setAttribute("download", "lecturas.xlsx");
			document.body.appendChild(link);
			link.click();
			link.remove();

			console.log("Archivo descargado con éxito");

			// Actualizar el estado para deshabilitar el botón
			setCompletedButtons(prev => ({ ...prev, export: true }));
		} catch (error) {
			console.error("Error al exportar el archivo:", error);
		}
	};

	const handleFileUpload = async (event) => {
		const file = event.target.files[0];
		if (!file) return;

		const formData = new FormData();
		formData.append("file", file);

		try {
			const user = JSON.parse(localStorage.getItem("user"));
			const token = user?.access;

			if (!token) {
				throw new Error("No se encontró el token en el localStorage");
			}

			const response = await axios.post(
				`${BACKEND_DOMAIN}/api/v1/upload-excel-consumo/`,
				formData,
				{
					headers: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "multipart/form-data",
					},
				}
			);

			const fileExtracted = response.data.data
			const mappedData = fileExtracted.map((row) => ({
				key: row.nro_cliente,
				mes: row.mes,
				año: row.año,
				sector: row.sector,
				nro_cliente: row.nro_cliente,
				nro_socio: row.nro_socio,
				nombre: row.nombre,
				direccion: row.direccion,
				recorrido: row.recorrido,
				lect_anterior: row.lect_anterior,
				lect_actual: row.lect_actual,
				consumo: row.consumo,
				meter_address: row.meter_address,

			}));
			setFileData(mappedData)
			// Actualizar el estado para deshabilitar el botón
			setCompletedButtons(prev => ({ ...prev, upload: true }));
		} catch (error) {
			console.error("Error al subir el archivo:", error);
		}
	};

	const handleUpdateConsumo = async () => {
		try {
			const user = JSON.parse(localStorage.getItem("user"));
			const token = user?.access;

			if (!token) {
				throw new Error("No se encontró el token en el localStorage");
			}

			// Mapeamos los datos de fileData al formato requerido por la API
			const payload = {
				data: fileData.map(row => ({
					meter_address: row.meter_address.toString(),
					lect_anterior: row.lect_anterior,
					lect_actual: row.lect_actual,
					consumo: row.consumo
				}))
			};

			const response = await axios.post(
				`${BACKEND_DOMAIN}/api/v1/update-consumo/`,
				payload,
				{
					headers: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json",
					},
				}
			);

			console.log("Datos actualizados con éxito:", response.data);

			// Actualizamos el estado para indicar que la operación fue completada
			setCompletedButtons(prev => ({ ...prev, save: true }));
		} catch (error) {
			console.error("Error al actualizar el consumo:", error);
		}
	};


	return (
		<div className="menu-container">
			<div className="menu-content-container">
				<div className="top-gray-container small">
					<h1 className="container-title">Planillas por fecha/sector: </h1>
				</div>
				<div className="renegotiate-form-container">
					<div className='step-buttons-container'>
						<div className="button-content">
							<button
								className="step-button"
								onClick={handleExport}
								disabled={completedButtons.export}
							>
								<img
									className="button-image"
									src={completedButtons.export ? blueCheck : blueExcel}
									alt="icon"
								/>
								<h2 className="button-text">
									{completedButtons.export
										? "Completado"
										: "Descargar planilla último mes"}
								</h2>
							</button>
						</div>

						<div className="button-content">
							<input
								type="file"
								accept=".xlsx"
								onChange={handleFileUpload}
								style={{ display: "none" }}
								id="fileUpload"
							/>
							<label htmlFor="fileUpload" className="step-button">
								<img
									className="button-image"
									src={completedButtons.upload ? blueCheck : bluePlus}
									alt="icon"
								/>
								<h2 className="button-text">
									{completedButtons.upload
										? "Archivo subido"
										: "Seleccionar planilla completada"}
								</h2>
							</label>
						</div>
						<div className="button-content">
							<button
								className="step-button"
								onClick={handleUpdateConsumo}
								disabled={completedButtons.save}
							>
								<img
									className="button-image"
									src={completedButtons.save ? blueCheck : blueDisk}
									alt="icon"
								/>
								<h2 className="button-text">
									{completedButtons.save ? "Completado" : "Guardar cambios"}
								</h2>
							</button>
						</div>
					</div>
					<Table columns={columns} dataSource={data} />
				</div>
			</div >
		</div >
	);
}
