import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import saveDisk from "../../../../images/save-disk.svg";
import cancelIcon from "../../../../images/cancel-icon.svg";
import { toast } from 'react-toastify';
import axios from 'axios';
import {BACKEND_DOMAIN} from "../../../../features/auth/authService";


export default function EditCharge() {
    const { chargeId } = useParams(); // Extraer chargeId desde la URL
    const navigate = useNavigate();

    const [movementData, setMovementData] = useState([]);
    const [formData, setFormData] = useState({
        costo: "",
        pagado: "",
        movement_id: "",
        emision_date: "",
        pay_date: "",
        boleta_fk: "",
    });

    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Crear objeto con solo los campos que han sido modificados
        const dataToUpdate = {};

        Object.keys(formData).forEach((key) => {
            const value = formData[key];

            // Verifica si el valor es una cadena antes de usar trim
            if (typeof value === "string" && value.trim()) {
                dataToUpdate[key] = value;
            } else if (value || value === false || value === 0) {
                // Si no es una cadena pero tiene un valor (por ejemplo, un número o false), lo añade directamente
                dataToUpdate[key] = value;
            }
        });

        // Obtener el token desde localStorage
        const user = JSON.parse(localStorage.getItem("user"));
        const token = user ? user.access : null;

        // Solo hacer la solicitud si hay datos a actualizar y hay un token
        if (Object.keys(dataToUpdate).length > 0 && token) {
            try {
                // Asegúrate de que los campos tienen los nombres correctos para el backend
                const payload = {
                    costo: dataToUpdate.costo,
                    fecha_emision_cargo: dataToUpdate.emision_date,
                    pagado: dataToUpdate.pagado,
                    fecha_pago: dataToUpdate.pay_date,
                    movimiento_fk: dataToUpdate.movement_id,
                    boleta_fk: dataToUpdate.boleta_fk
                };

                const response = await axios.put(`${BACKEND_DOMAIN}/api/v1/cargo/editar/${chargeId}/`, payload, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });

                toast.success("Cambios guardados con éxito");
                navigate("/menu_apr");
            } catch (error) {
                console.error("Error al actualizar los datos:", error.response ? error.response.data : error.message);
                toast.error("Error al actualizar los datos");
            }
        } else {
            toast.error("No hay datos para actualizar o falta el token");
        }
    };

    useEffect(() => {
        const fetchChargeData = async () => {
            // Obtener el token desde localStorage
            const user = JSON.parse(localStorage.getItem("user"));
            const token = user ? user.access : null;

            // Verificar que el token esté disponible
            if (!token) {
                toast.error("Token de autenticación no disponible");
                return;
            }

            try {
                const response = await axios.get(`${BACKEND_DOMAIN}/api/v1/cargos/${chargeId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`, // Incluir el token en los headers
                    },
                });

                // Asignar los datos al estado formData
                const chargeData = response.data;
                setFormData({
                    costo: chargeData.costo || "",
                    pagado: chargeData.pagado || "",
                    movement_id: chargeData.movimiento_fk?.id || "",
                    emision_date: chargeData.fecha_emision_cargo ? chargeData.fecha_emision_cargo.split('T')[0] : "",
                    pay_date: chargeData.fecha_pago ? chargeData.fecha_pago.split('T')[0] : "",
                    boleta_fk: chargeData.boleta_fk
                });

            } catch (error) {
                console.error("Error al obtener los datos del cargo:", error.response ? error.response.data : error.message);
                toast.error("Error al obtener los datos del cargo");
            }
        };

        fetchChargeData();
    }, [chargeId]);

    useEffect(() => {
        const fetchMovimientos = async () => {
            // Obtener el token desde localStorage
            const user = JSON.parse(localStorage.getItem("user"));
            const token = user ? user.access : null;

            // Verificar que el token esté disponible
            if (!token) {
                toast.error("Token de autenticación no disponible");
                return;
            }

            try {
                const response = await axios.get(`${BACKEND_DOMAIN}/api/v1/movimiento/apr/`, {
                    headers: {
                        Authorization: `Bearer ${token}`, // Incluir el token en los headers
                    },
                });
                setMovementData(response.data); // Guardar la respuesta en el estado movementData
            } catch (error) {
                console.error("Error al obtener los datos del movimiento:", error.response ? error.response.data : error.message);
                toast.error("Error al obtener los datos del movimiento");
            }
        };

        fetchMovimientos();
    });



    return (
        <div className="menu-container">
            <div className="menu-content-container">
                <div className="my-profile-container">
                    <div className="form-container">
                        <h1 className="container-title">Editar cargo usuario</h1>
                        <form className="edit-user-form" onSubmit={handleSubmit}>
                            <div className="input-container">
                                <label>Costo</label>
                                <input
                                    type="text"
                                    name="costo"
                                    value={formData.costo}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="input-container">
                                <label>Pagado</label>
                                <select
                                    name="pagado"
                                    value={formData.pagado}
                                    onChange={handleChange}
                                >
                                    <option value="">Seleccione una opción</option>
                                    <option value={true}>Si</option>
                                    <option value={false}>No</option>
                                </select>
                            </div>
                            <div className="input-container">
                                <label>Tipo de movimiento</label>
                                <select
                                    name="movement_id"
                                    value={formData.movement_id}
                                    onChange={handleChange}
                                >
                                    <option value="">Seleccione una opción</option>
                                    {movementData.map((movimiento) => (
                                        <option key={movimiento.id} value={movimiento.id}>
                                            {movimiento.descripcion}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="input-container">
                                <label>Fecha de emisión:</label>
                                <input type="date" name="emision_date" value={formData.emision_date} onChange={handleChange} required />
                            </div>
                            <div className="input-container">
                                <label>Fecha de pago:</label>
                                <input type="date" name="pay_date" value={formData.pay_date} onChange={handleChange} required />
                            </div>

                            <div className="buttons-container">
                                <button
                                    type="button"
                                    className="btn-cancel"
                                    onClick={() => navigate("/menu_apr/tarifas")}
                                >
                                    <img src={cancelIcon} className="cancel-image" />
                                    Cancelar
                                </button>
                                <button type="submit" className="btn-save">
                                    <img src={saveDisk} className="save-image" />
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
