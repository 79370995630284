import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import saveDisk from "../../../../images/save-disk.svg";
import cancelIcon from "../../../../images/cancel-icon.svg";
import {BACKEND_DOMAIN} from "../../../../features/auth/authService";

export default function WatermeterOutletCreate() {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        assigned_apr: "",
        cod_arranque: "",
        descripcion: "",
        cargo_fijo_mantencion: "",
        cargo_fijo_alcantarillado: "",
    });

    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const user = JSON.parse(localStorage.getItem("user"));
        const token = user?.access;

        if (!token) {
            toast.error("Token de autenticación no disponible");
            return;
        }

        let updatedFormData = { ...formData }; // Clonamos el estado actual

        try {
            const response = await axios.get(`${BACKEND_DOMAIN}/api/v1/user-detail/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const userData = response.data;
            updatedFormData = {
                ...updatedFormData,
                assigned_apr: userData.assigned_apr !== undefined ? userData.assigned_apr : "",
            };

            toast.success("Datos del usuario cargados correctamente");
        } catch (error) {
            console.error("Error al obtener detalles del usuario:", error.response ? error.response.data : error.message);
            toast.error("Error al obtener detalles del usuario");
            return; // Detenemos la ejecución si hay un error en la solicitud GET
        }

        try {
            const response = await axios.post(
                `${BACKEND_DOMAIN}/api/v1/arranque/apr/create/`,
                updatedFormData, // Usamos el objeto actualizado
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success("Arranque creado con éxito");
            navigate("/menu_apr/watermeters_outlets");
        } catch (error) {
            console.error("Error al crear el arranque:", error.response ? error.response.data : error.message);
            toast.error("Error al crear el arranque");
        }
    };


    return (
        <div className="menu-container">
            <div className="menu-content-container">
                <div className="my-profile-container">
                    <div className="form-container">
                        <h1 className="form-title">Crear Nuevo Arranque</h1>
                        <form className="edit-user-form" onSubmit={handleSubmit}>
                            <div className="input-container">
                                <label>Codigo arranque</label>
                                <input
                                    type="text"
                                    name="cod_arranque"
                                    value={formData.cod_arranque}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="input-container">
                                <label>Descripción de arranque</label>
                                <input
                                    type="text"
                                    name="descripcion"
                                    value={formData.descripcion}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="input-container">
                                <label>Cargo fijo de mantención</label>
                                <input
                                    type="text"
                                    name="cargo_fijo_mantencion"
                                    value={formData.cargo_fijo_mantencion}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="input-container">
                                <label>Cargo fijo de alcantarillado</label>
                                <input
                                    type="text"
                                    name="cargo_fijo_alcantarillado"
                                    value={formData.cargo_fijo_alcantarillado}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="buttons-container">
                                <button
                                    type="button"
                                    className="btn-cancel"
                                    onClick={() => navigate("/menu_apr")}
                                >
                                    <img src={cancelIcon} className="cancel-image" />
                                    Cancelar
                                </button>
                                <button type="submit" className="btn-save">
                                    <img src={saveDisk} className="save-image" />
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
