import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { BACKEND_DOMAIN } from "../../../../features/auth/authService";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import saveDisk from "../../../../images/save-disk.svg";
import cancelIcon from "../../../../images/cancel-icon.svg";

export default function WatermeterOutletEdit() {
    const navigate = useNavigate();
    const { outletId } = useParams(); // Extraer outletId desde la URL
    const [outletInfo, setOutletInfo] = useState(null)

    const [formData, setFormData] = useState({
        assigned_apr: "",
        cod_arranque: "",
        descripcion: "",
        cargo_fijo_mantencion: "",
        cargo_fijo_alcantarillado: "",
    });

    useEffect(() => {
        const fetchOutletDetails = async () => {
            // setIsLoading(true);
            // setError(null);

            const user = JSON.parse(localStorage.getItem("user"));
            const token = user?.access;


            if (!token) {
                toast.error("Token de autenticación no disponible");
                return;
            }

            try {

                const response = await axios.get(`${BACKEND_DOMAIN}/api/v1/arranques/${outletId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setOutletInfo(response.data);
            }
            catch (error) {
                console.error("Error fetching outlet details:", error);
                toast.error("Error al obtener los datos del arranque"); // Set a user-friendly error message
            }
            // finally {
            //     setIsLoading(false);
            // }
        };

        fetchOutletDetails();
    }, []);

    useEffect(() => {
        if (outletInfo) {
            console.log("outletInfo", outletInfo)
            setFormData({
                assigned_apr: outletInfo.assigned_apr || "",
                cod_arranque: outletInfo.cod_arranque || "",
                descripcion: outletInfo.descripcion || "",
                cargo_fijo_mantencion: outletInfo.cargo_fijo_mantencion !== undefined ? outletInfo.cargo_fijo_mantencion : "",
                cargo_fijo_alcantarillado: outletInfo.cargo_fijo_alcantarillado !== undefined ? outletInfo.cargo_fijo_alcantarillado : "",
            })
        }
    }, [outletInfo]);

    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const dataToUpdate = {};
        Object.keys(formData).forEach((key) => {
            const value = formData[key];

            if (typeof value === 'string' && value.trim()) {
                dataToUpdate[key] = value;
            } else if (value) {
                dataToUpdate[key] = value;
            }
        })

        const user = JSON.parse(localStorage.getItem("user"));
        const token = user ? user.access : null;

        if (Object.keys(dataToUpdate).length > 0 && token) {
            try {
                const response = await axios.put(`${BACKEND_DOMAIN}/api/v1/arranque/edit/${outletId}/`, dataToUpdate, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });

                toast.success("Cambios guardados con éxito");
                navigate("/menu_apr/watermeters_outlets");
            } catch (error) {
                console.error("Error al actualizar los datos:", error.response ? error.response.data : error.message)
                toast.error("Error al actualizar los datos");

            }
        } else {
            toast.error("No hay datos para actualizar o falta el token");
        }

    };


    return (
        <div className="menu-container">
            <div className="menu-content-container">
                <div className="my-profile-container">
                    <div className="form-container">
                        <h1 className="form-title">Editar Arranque</h1>
                        <form className="edit-user-form" onSubmit={handleSubmit}>
                            <div className="input-container">
                                <label>Codigo arranque</label>
                                <input
                                    type="text"
                                    name="cod_arranque"
                                    value={formData.cod_arranque}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="input-container">
                                <label>Descripción de arranque</label>
                                <input
                                    type="text"
                                    name="descripcion"
                                    value={formData.descripcion}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="input-container">
                                <label>Cargo fijo de mantención</label>
                                <input
                                    type="text"
                                    name="cargo_fijo_mantencion"
                                    value={formData.cargo_fijo_mantencion}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="input-container">
                                <label>Cargo fijo de alcantarillado</label>
                                <input
                                    type="text"
                                    name="cargo_fijo_alcantarillado"
                                    value={formData.cargo_fijo_alcantarillado}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="buttons-container">
                                <button
                                    type="button"
                                    className="btn-cancel"
                                    onClick={() => navigate("/menu_apr")}
                                >
                                    <img src={cancelIcon} className="cancel-image" />
                                    Cancelar
                                </button>
                                <button type="submit" className="btn-save" >
                                    <img src={saveDisk} className="save-image" />
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
