import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import saveDisk from "../../../../images/save-disk.svg";
import cancelIcon from "../../../../images/cancel-icon.svg";
import {BACKEND_DOMAIN} from "../../../../features/auth/authService";

export default function SectorEdit() {
	const navigate = useNavigate();
	const { sectorId } = useParams(); // Extraer sectorId desde la URL

	const [sectorInfo, setSectorInfo] = useState(null); // Para almacenar la información del sector
	const [formData, setFormData] = useState({
		sector_name: "",
		comuna_fk: null, // Comuna que se conservará
	});

	// Cargar datos del sector al montar el componente
	useEffect(() => {
		const fetchSectorData = async () => {
			const user = JSON.parse(localStorage.getItem("user"));
			const token = user?.access;

			if (!token) {
				toast.error("Token de autenticación no disponible");
				return;
			}

			try {
				const response = await axios.get(`${BACKEND_DOMAIN}/api/v1/sectores/${sectorId}`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				setSectorInfo(response.data);
			} catch (error) {
				console.error("Error al obtener datos del sector:", error.response ? error.response.data : error.message);
				toast.error("Error al cargar los datos del sector");
			}
		};

		fetchSectorData();
	}, [sectorId]);

	useEffect(() => {
		if (sectorInfo) {
			setFormData({
				sector_name: sectorInfo.sector_name || "",
				comuna_fk: sectorInfo.comuna_fk || null,
			});
		}
	}, [sectorInfo]);

	const handleChange = (e) => {
		setFormData((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const dataToUpdate = {};
		Object.keys(formData).forEach((key) => {
			const value = formData[key];

			if (typeof value === "string" && value.trim()) {
				dataToUpdate[key] = value;
			} else if (value) {
				dataToUpdate[key] = value;
			}
		});

		const user = JSON.parse(localStorage.getItem("user"));
		const token = user ? user.access : null;

		if (Object.keys(dataToUpdate).length > 0 && token) {
			try {
				const response = await axios.put(`${BACKEND_DOMAIN}/api/v1/sector/modificar/${sectorId}/`, dataToUpdate, {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});

				toast.success("Sector actualizado correctamente");
				navigate("/menu_apr/sectors/");
			} catch (error) {
				console.error("Error al actualizar el sector:", error.response ? error.response.data : error.message);
				toast.error("Error al actualizar el sector");
			}
		} else {
			toast.error("No hay datos para actualizar o falta el token");
		}
	};

	return (
		<div className="menu-container">
			<div className="menu-content-container">
				<div className="my-profile-container">
					<div className="form-container">
						<h1 className="form-title">Editar Sector</h1>
						<form className="edit-user-form" onSubmit={handleSubmit}>
							<div className="input-container">
								<label>Nombre del Sector</label>
								<input
									type="text"
									name="sector_name"
									value={formData.sector_name}
									onChange={handleChange}
								/>
							</div>
							<div className="buttons-container">
								<button type="button" className="btn-cancel" onClick={() => navigate("/menu_apr")}>
									<img src={cancelIcon} className="cancel-image" />
									Cancelar
								</button>
								<button type="submit" className="btn-save">
									<img src={saveDisk} className="save-image" />
									Guardar
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}
