import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import saveDisk from "../../../../images/save-disk.svg";
import cancelIcon from "../../../../images/cancel-icon.svg";
import {BACKEND_DOMAIN} from "../../../../features/auth/authService";


export default function ClientStateEdit() {
	const navigate = useNavigate();
	const { clientTypeId } = useParams(); // Extraer clientTypeId desde la URL

	const [formData, setFormData] = useState({
		id: "",
		descripcion: "",
		assigned_apr: null, // Para mantener el valor de assigned_apr
	});

	// Fetch initial data when the component mounts
	useEffect(() => {
		const fetchClientStateDetails = async () => {
			const user = JSON.parse(localStorage.getItem("user"));
			const token = user?.access;

			if (!token) {
				toast.error("Token de autenticación no disponible");
				return;
			}

			try {
				const response = await axios.get(
					`${BACKEND_DOMAIN}/api/v1/estadoscliente/${clientTypeId}`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);
				const data = response.data;

				// Set the form data, including assigned_apr which is fixed
				setFormData({
					id: data.id || "", // Asignamos el 'id' como código
					descripcion: data.descripcion || "",
					assigned_apr: data.assigned_apr, // Mantener el valor de assigned_apr
				});
			} catch (error) {
				console.error("Error fetching client state details:", error);
				toast.error("Error al obtener los datos del estado de cliente");
			}
		};

		fetchClientStateDetails();
	}, [clientTypeId]);

	const handleChange = (e) => {
		setFormData((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		// Crear el objeto con los datos a enviar, incluyendo el código original
		const dataToUpdate = {
			id: formData.id, // Asegúrate de incluir el código actual
			descripcion: formData.descripcion,
			assigned_apr: formData.assigned_apr, // Incluir assigned_apr que no se muestra
		};

		const user = JSON.parse(localStorage.getItem("user"));
		const token = user?.access;

		if (dataToUpdate.descripcion && dataToUpdate.id && token) {
			try {
				// Enviar los datos con PUT
				await axios.put(
					`${BACKEND_DOMAIN}/api/v1/estadocliente/edit/${clientTypeId}/`,
					dataToUpdate,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				);

				toast.success("Cambios guardados con éxito");
				navigate("/menu_apr/clients_states/"); // Redirigir a la lista después de guardar
			} catch (error) {
				console.error("Error al actualizar los datos:", error.response?.data || error.message);
				toast.error("Error al actualizar los datos");
			}
		} else {
			toast.error("Faltan datos para actualizar o falta el token");
		}
	};

	return (
		<div className="menu-container">
			<div className="menu-content-container">
				<div className="my-profile-container">
					<div className="form-container">
						<h1 className="form-title">Editar tipo de estado</h1>
						<form className="edit-user-form" onSubmit={handleSubmit}>
							<div className="input-container">
								<label>Código de estado</label>
								<input
									type="text"
									name="id"
									value={formData.id}
									onChange={handleChange}
								/>
							</div>
							<div className="input-container">
								<label>Descripción de tipo de estado</label>
								<input
									type="text"
									name="descripcion"
									value={formData.descripcion}
									onChange={handleChange}
								/>
							</div>
							<div className="input-container">
								<label>Proceso de cálculo:</label>
								<select
									name="calculation_process"
									value={formData.applied_to}
									onChange={handleChange}
								>
									<option value="">Proceso de cálculo</option>
									<option value="yes">Si</option>
									<option value="no">No</option>
								</select>
							</div>
							<div className="buttons-container">
								<button
									type="button"
									className="btn-cancel"
									onClick={() => navigate("/menu_apr")}
								>
									<img src={cancelIcon} className="cancel-image" />
									Cancelar
								</button>
								<button type="submit" className="btn-save">
									<img src={saveDisk} className="save-image" />
									Guardar
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}
