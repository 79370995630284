import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import saveDisk from "../../../images/save-disk.svg";
import cancelIcon from "../../../images/cancel-icon.svg";
import { Button, Input, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import dayjs from "dayjs";
import axios from "axios";
import {BACKEND_DOMAIN} from "../../../features/auth/authService";


export default function RequirementsSpreadsheets() {
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [stateData, setStateData] = useState([]);
    const [sectorData, setSectorData] = useState([]);


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };


    const [formData, setFormData] = useState({
        to: "",
        from: "",
        client_type: "",
        sector_id: "",
    })

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });


    useEffect(() => {
        const fetchMedidores = async () => {
            try {
                const user = JSON.parse(localStorage.getItem("user"));
                const token = user?.access;

                if (!token) {
                    throw new Error("No se encontró el token en el localStorage");
                }

                const response = await axios.get(`${BACKEND_DOMAIN}/api/v1/getmedidores/apr/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const medidores = response.data;

                // Mapeo de los datos
                const mappedData = medidores.map((medidor) => ({
                    key: medidor.user.user_id,
                    client_number: medidor.id, // Número de cliente
                    watermeter_number: medidor.meter_addr, // Número de medidor
                    name: `${medidor.user.first_name} ${medidor.user.last_name}`,
                    state: medidor.consumo.valve_status === "Opened" ? "Activo" : "Inactivo",
                    rut: formatRut(medidor.user.rut),
                    email: medidor.user.email,
                    address: medidor.user.address,
                    sector: medidor.user.sector,
                    edit: "Editar",
                }));

                setData(mappedData);
            } catch (error) {
                console.error("Error fetching medidores:", error);
            }
        };


        fetchMedidores();
    }, []);


    useEffect(() => {
        const fetchClientsStates = async () => {
            try {
                const user = JSON.parse(localStorage.getItem("user"));
                const token = user?.access;

                if (!token) {
                    throw new Error("No se encontró el token en el localStorage");
                }

                const response = await axios.get(`${BACKEND_DOMAIN}/api/v1/mis-estados-cliente/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const states = response.data;
                const mappedData = states.map((state) => ({
                    key: state.id,
                    id: state.id,
                    descripcion: state.descripcion,
                    assignedAPR: state.assigned_apr,
                }));

                setStateData(mappedData);
            } catch (error) {
                console.error("Error fetching client states:", error);
                setError(error.message);
            }
        };

        fetchClientsStates();
    }, []);


    useEffect(() => {
        const fetchSectors = async () => {
            try {
                const user = JSON.parse(localStorage.getItem("user"));
                const token = user?.access;

                if (!token) {
                    throw new Error("No se encontró el token en el localStorage");
                }

                const response = await axios.get(`${BACKEND_DOMAIN}/api/v1/mis-sectores/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const sectors = response.data;
                const mappedData = sectors.map((sector) => ({
                    key: sector.id,
                    id: sector.id,
                    sector_name: sector.sector_name,
                }));

                setSectorData(mappedData);
            } catch (error) {
                console.error("Error fetching client states:", error);
                setError(error.message);
            }
        };

        fetchSectors();
    }, []);


    const columns = [
        {
            title: "N° Cliente",
            dataIndex: "client_number",
            key: "client_number",
            width: "10%",
            ...getColumnSearchProps("client_number"),
        },
        {
            title: "N° Medidor",
            dataIndex: "watermeter_number",
            key: "watermeter_number",
            width: "20%",
            ...getColumnSearchProps("watermeter_number"),
        },
        {
            title: "Rut",
            dataIndex: "rut",
            key: "rut",
            width: "15%",
            ...getColumnSearchProps("rut"),
            sorter: (a, b) => a.rut.length - b.rut.length,
            sortDirections: ["descend", "ascend"],
        },
        {
            title: "Nombre",
            dataIndex: "name",
            key: "name",
            width: "15%",
            ...getColumnSearchProps("name"),
        },
        {
            title: "Estado",
            dataIndex: "state",
            key: "state",
            width: "10%",
            ...getColumnSearchProps("state"),
            render: (_, record) => (
                <Space size="middle">
                    {record.state === "Activo" ? (
                        <div className="active-state">Activo</div>
                    ) : record.state === "Inactivo" ? (
                        <div className="inactive-state">Inactivo</div>
                    ) : record.state === "Revisar" ? (
                        <div className="review-state">Revisión</div>
                    ) : (
                        ""
                    )}
                </Space>
            ),
        }
    ];

    const formatRut = (rut) => {
        const rutStr = String(rut).trim();
        const rutDigits = rutStr.slice(0, -1);
        const dv = rutStr.slice(-1);
        let formattedRut = rutDigits.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        formattedRut = `${formattedRut}-${dv}`;
        return formattedRut;
    };

    const handleExport = async () => {
        try {
            const { from, to, client_type, sector_id } = formData;

            // Construir la URL con los parámetros
            const url = `${BACKEND_DOMAIN}/api/v1/exportar-excel-consumos/?estado_cliente=${client_type}&sector=${sector_id}&fecha_inicio=${from}&fecha_fin=${to}`;

            const user = JSON.parse(localStorage.getItem("user"));
            const token = user?.access;

            if (!token) {
                throw new Error("No se encontró el token en el localStorage");
            }

            // Realizar la solicitud GET
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                responseType: "blob", // Importante para manejar archivos
            });

            // Descargar el archivo
            const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = urlBlob;
            link.setAttribute("download", "consumos.xlsx");
            document.body.appendChild(link);
            link.click();
            link.remove();

            console.log("Archivo exportado con éxito");
        } catch (error) {
            console.error("Error al exportar el archivo:", error);
        }
    };



    return (
        <div className="menu-container">
            <div className="menu-content-container">
                <div className="top-gray-container small">
                    <h1 className="container-title">Planillas por fecha/sector: </h1>
                </div>
                <div className="renegotiate-main-container">
                    <div className="renegotiate-form-container">
                        <div className='top-form-container'>
                            <div className='form-input-middle-container spreadsheets'>
                                <div className='payment-method-container'>
                                    <span className='input-title-text'>Sector</span>
                                    <select name="sector_id" id="sector_id" value={formData.sector_id} onChange={handleChange} className="select-input">
                                        <option key={0} value={""}>Seleccione una opción</option>
                                        {sectorData.map((sector) => (
                                            <option key={sector.id} value={sector.id}>
                                                {sector.sector_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='payment-method-container'>
                                    <span className='input-title-text'>Estado Cliente</span>
                                    <select
                                        id="client_type"
                                        name="client_type"
                                        value={formData.client_type}
                                        onChange={handleChange}
                                        className="select-input"
                                    >
                                        <option key={0} value={""}>Seleccione una opción</option>
                                        {stateData.map((state) => (
                                            <option key={state.id} value={state.id}>
                                                {state.descripcion}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='form-input-middle-container spreadsheets'>
                                <div className='payment-method-container'>
                                    <span className='input-title-text'>Desde</span>
                                    <input
                                        className="select-input"
                                        type="date"
                                        id="from"
                                        name="from"
                                        value={dayjs(formData.from).format("YYYY-MM-DD")}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='payment-method-container'>
                                    <span className='input-title-text'>Hasta</span>
                                    <input
                                        className="select-input"
                                        type="date"
                                        id="to"
                                        name="to"
                                        value={dayjs(formData.to).format("YYYY-MM-DD")}
                                        onChange={handleChange}
                                    />
                                </div>

                            </div>

                            <div className='form-input-last-container'>
                                <div className="buttons-container">
                                    <button className="btn-save" type="primary" onClick={handleExport}>
                                        <img src={saveDisk} className="save-image" />
                                        Descargar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='bottom-table-container'>
                        <Table columns={columns} dataSource={data} pagination={{ pageSize: 5 }} />
                    </div>
                </div>
            </div>
        </div>
    )
}
