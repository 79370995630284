import React, { useRef, useState, useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table, Modal } from "antd";
import Highlighter from "react-highlight-words";
import { BACKEND_DOMAIN } from "../../features/auth/authService";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import plusIcon from "../../images/plus-icon.svg";


const WatermeterList = () => {
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");
	const searchInput = useRef(null);

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [actionType, setActionType] = useState(""); // activar, desactivar o revisar

	// const [medidores, setMedidores] = useState(null); // Estado para los datos obtenidos
	const [data, setData] = useState([]); // Estado para almacenar los datos mapeados
	const [error, setError] = useState(null); // Estado para manejar errores

	const navigate = useNavigate();

	const showModal = (action) => {
		setActionType(action);
		setIsModalVisible(true);
	};

	const handleOk = () => {
		// Lógica para confirmar la acción
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
	};

	const handleAddLectureClick = (userId) => {
		// Redirigir al componente de edición pasando el ID del usuario
		navigate(`/menu_apr/agregar-lectura/${userId}`);
	};

	const handleAddClick = () => {
		navigate(`/menu_apr/create_watermeter/`);
	};

	useEffect(() => {
		const fetchMedidores = async () => {
			try {
				// Obtener el token almacenado en el localStorage
				const user = JSON.parse(localStorage.getItem("user")); // Asume que 'user' tiene el token
				const token = user?.access; // Accede al token de acceso

				if (!token) {
					throw new Error("No se encontró el token en el localStorage");
				}

				// Realizar la consulta GET con el token en los headers
				const response = await axios.get(`${BACKEND_DOMAIN}/api/v1/getmedidores/apr/`, {
					headers: {
						Authorization: `Bearer ${token}`, // Incluir el token en los headers
					},
				});

				const medidores = response.data;
				// Mapeo de los datos a la estructura requerida por antd
				const mappedData = medidores.map((medidor, index) => ({
					key: medidor.user.user_id, // El key debe ser un string único
					meter: medidor.meter_addr,
					state: medidor.consumo.valve_status === "Opened" ? "Activo" : "Inactivo", // Lógica simple para estado
					rut: formatRut(medidor.user.rut),
					address: medidor.user.address,
					last_lecture: new Date(medidor.consumo.read_date).toLocaleString(), // Formato de fecha legible
					activable: medidor.consumo.valve_status === "Opened" ? "Cerrar Válvula" : "Abrir Válvula", // Lógica para botón de acción
					id_medidor: medidor.id,
				}));

				// Guardar los datos mapeados en el estado y en localStorage
				setData(mappedData);
				localStorage.setItem("medidoresData", JSON.stringify(medidores)); // Almacenar en localStorage
			} catch (error) {
				console.error("Error fetching medidores:", error);
				setError(error.message); // Almacenar el mensaje de error
			}
		};

		fetchMedidores(); // Llamar a la función al montar el componente
	}, []);

	// console.log("data", data)

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
			<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					ref={searchInput}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ marginBottom: 8, display: "block" }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}
					>
						Reset
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm({ closeDropdown: false });
							setSearchText(selectedKeys[0]);
							setSearchedColumn(dataIndex);
						}}
					>
						Filter
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							close();
						}}
					>
						close
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownOpenChange: (visible) => {
			if (visible) {
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ""}
				/>
			) : (
				text
			),
	});

	const columns = [
		{
			title: "Nro Medidor",
			dataIndex: "meter",
			key: "meter",
			width: "10%",
			...getColumnSearchProps("meter"),
		},
		{
			title: "Rut Asociado",
			dataIndex: "rut",
			key: "rut",
			width: "10%",
			...getColumnSearchProps("rut"),
		},
		{
			title: "Dirección",
			dataIndex: "address",
			key: "address",
			width: "25%",
			...getColumnSearchProps("address"),
			sorter: (a, b) => a.address.length - b.address.length,
			sortDirections: ["descend", "ascend"],
		},
		{
			title: "Ultima Lectura",
			dataIndex: "last_lecture",
			key: "last_lecture",
			width: "15%",
			...getColumnSearchProps("last_lecture"),
		},
		// {
		// 	title: "Acción",
		// 	dataIndex: "activable",
		// 	key: "activable",
		// 	// width: "15%",
		// 	// ...getColumnSearchProps("activable"),
		// },
		{
			title: "Estado",
			dataIndex: "state",
			key: "state",
			width: "10%",
			...getColumnSearchProps("state"),
			render: (_, record) => (
				<div className="state-container">
					{record.state === "Activo" ? (
						<div className="active-state">Activo</div>
					) : record.state === "Inactivo" ? (
						<div className="inactive-state">Inactivo</div>
					) : record.state === "Revisar" ? (
						<div className="review-state">Revisión</div>
					) : (
						""
					)}
				</div>
			),
		},
		{
			title: "Acción",
			key: "action",
			render: (_, record) => (
				<div className="actions-container">
					{record.state === "Activo" ? (
						<a className="inactive-link" onClick={() => showModal("Desactivar")}>
							Cerrar válvula
						</a>
					) : record.state === "Inactivo" ? (
						<a className="active-link" onClick={() => showModal("Activar")}>
							Abrir válvula
						</a>
					) : record.state === "Revisar" ? (
						<a className="review-link">En revisión</a>
					) : (
						""
					)}
					<a className="active-link lecture" onClick={() => handleAddLectureClick(record.id_medidor)}>
						Añadir Lectura
					</a>
				</div>
			),
		},
	];

	const formatRut = (rut) => {
		// Convertir el rut a string para asegurar su manipulación como cadena
		const rutStr = String(rut).trim();

		// Obtener el dígito verificador y los dígitos del rut
		const rutDigits = rutStr.slice(0, -1);
		const dv = rutStr.slice(-1);

		// Formatear los dígitos con puntos
		let formattedRut = rutDigits.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

		// Agregar el dígito verificador
		formattedRut = `${formattedRut}-${dv}`;

		return formattedRut;
	};

	return (
		<>
			{isModalVisible ? (
				<Modal
					title="Está a punto de ejecutar una acción importante"
					open={isModalVisible}
					onOk={handleOk}
					onCancel={handleCancel}
					okText="Confirmar"
					cancelText="Cancelar"
				>
					<p>¿Está seguro de querer {actionType.toLowerCase()} la válvula?</p>
				</Modal>
			) : (
				""
			)}
			<div className="menu-container">
				<div className="menu-content-container">
					<div className="top-gray-container small">
						<h1 className="container-title">Medidores</h1>
						<div className="add-button-container">
							<img className="plus-icon" src={plusIcon} />
							<a className="add-button" onClick={() => handleAddClick("")}>
								Añadir medidor
							</a>
						</div>
					</div>
					<Table columns={columns} dataSource={data} />
				</div>
			</div>
		</>
	);
};

export default WatermeterList;
