import {BrowserRouter as Router, Routes, Route, Outlet} from "react-router-dom";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import NotFound from "./components/NotFound";
import Resources from "./components/Resources";
import Login from "./components/Login";
import Solutions from "./components/Solutions";

import "./App.scss";
import MenuUser from "./components/menu/MenuUser";
import {ToastContainer} from "react-toastify";
import MenuNav from "./components/menu/MenuNav";
import WatermeterList from "./components/menu/WatermetersList";
import ClientsStates from "./components/menu/watermeters_submenu/ClientsStates";
import Fares from "./components/menu/watermeters_submenu/Fares";
import MovementsList from "./components/menu/watermeters_submenu/MovementsLists";
import PaymentMethods from "./components/menu/watermeters_submenu/PaymentMethods";
import Sectors from "./components/menu/watermeters_submenu/Sectors";
import WatermetersOutlets from "./components/menu/watermeters_submenu/WatermetersOutlets";

import UserLists from "./components/menu/UsersLists";
import ClientsMovements from "./components/menu/users_submenu/ClientsMovements";
import ReadingEntries from "./components/menu/users_submenu/ReadingEntries";
import RequirementsSpreadsheets from "./components/menu/users_submenu/RequirementsSpreadsheets";

import MenuApr from "./components/menu/MenuApr";
import Profile from "./components/menu/Profile";
import ProfileApr from "./components/menu/ProfileApr";
import Watermeter from "./components/menu/Watermeter";
import EditUser from "./components/menu/EditUser";
import AddUser from "./components/menu/users_submenu/forms/AddUser";
import AddLecture from "./components/menu/AddLecture";
import Consumptions from "./components/menu/Consumptions";
import FileApi from "./components/menu/FileApi";
import WatermeterOutletEdit from "./components/menu/watermeters_submenu/forms/WatermeterOutletEdit";
import FareEdit from "./components/menu/watermeters_submenu/forms/FareEdit";
import SectorEdit from "./components/menu/watermeters_submenu/forms/SectorEdit";
import MovementEdit from "./components/menu/watermeters_submenu/forms/MovementEdit";
import ClientTypeEdit from "./components/menu/watermeters_submenu/forms/ClientStateEdit";
import PaymentMethodEdit from "./components/menu/watermeters_submenu/forms/PaymentMethodEdit";
import MonthlyLectures from "./components/menu/users_submenu/MonthlyLectures";
import Pay from "./components/menu/users_submenu/forms/Pay";
import WatermeterOutletCreate from "./components/menu/watermeters_submenu/forms/WatermeterOutletCreate";
import FareCreate from "./components/menu/watermeters_submenu/forms/FareCreate";
import SectorCreate from "./components/menu/watermeters_submenu/forms/SectorCreate";
import MovementCreate from "./components/menu/watermeters_submenu/forms/MovementCreate";
import ClientStateEdit from "./components/menu/watermeters_submenu/forms/ClientStateEdit";
import ClientStateCreate from "./components/menu/watermeters_submenu/forms/ClientStateCreate";
import PaymentMethodCreate from "./components/menu/watermeters_submenu/forms/PaymentMethodCreate";
import WatermeterCreate from "./components/menu/watermeters_submenu/forms/WatermeterCreate";
import Renegotiate from "./components/menu/users_submenu/forms/Renegotiate";
import AddCharge from "./components/menu/users_submenu/forms/AddCharge";
import UserCharges from "./components/menu/users_submenu/forms/UserCharges";
import EditCharge from "./components/menu/users_submenu/forms/EditCharge";
import Bidding from "./components/temp-bidding/Bidding";
import Offers from "./components/temp-bidding/Offers";
import OfferDetail from "./components/temp-bidding/OfferDetail";
import OfferFiles from "./components/temp-bidding/OfferFiles";

function App() {
	return (
		<>
			<Router>
				<Routes>
					<Route path="/" element={<WithNavAndFooter />}>
						<Route index element={<Home />} />
						<Route path="/solutions" element={<Solutions />} />
						<Route path="/about" element={<About />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/resources" element={<Resources />} />
						<Route path="/login" element={<Login />} />
						<Route path="*" element={<NotFound />} />
						<Route path="/limari" element={<Bidding />} />
						<Route path="/limari/ofertas" element={<Offers />} />
						<Route path="/limari/ofertas/1" element={<OfferDetail />} />
						<Route path="/limari/ofertas/1/files" element={<OfferFiles />} />

					</Route>

					<Route path="/menu" element={<WithoutNavAndFooter />}>
						<Route index element={<MenuUser />} />
						<Route path='/menu/profile' element={<Profile />}/>
						<Route path='/menu/watermeter' element={<Watermeter />}/>
					</Route>

					<Route path="/menu_apr" element={<WithoutNavAndFooter />}>
						<Route index element={<MenuApr />} />
						<Route path='/menu_apr/profile' element={<ProfileApr />}/>
						
						{/* WATERMETER SUBMENU */}
						<Route path='/menu_apr/watermeters_list' element={<WatermeterList />}/>
						<Route path="/menu_apr/create_watermeter" element={<WatermeterCreate />} />

						<Route path='/menu_apr/watermeters_outlets' element={<WatermetersOutlets />}/>
						<Route path="/menu_apr/create_watermeter_outlet" element={<WatermeterOutletCreate />} />
						<Route path="/menu_apr/edit_watermeter_outlet/:outletId" element={<WatermeterOutletEdit />} />

						<Route path='/menu_apr/fares' element={<Fares />}/>
						<Route path="/menu_apr/create_fare" element={<FareCreate />} />
						<Route path="/menu_apr/edit_fare/:fareId" element={<FareEdit />} />

						<Route path='/menu_apr/sectors' element={<Sectors />}/>
						<Route path="/menu_apr/create_sector" element={<SectorCreate />} />
						<Route path="/menu_apr/edit_sector/:sectorId" element={<SectorEdit />} />

						<Route path='/menu_apr/movements_list' element={<MovementsList />}/>
						<Route path="/menu_apr/create_movement" element={<MovementCreate />} />
						<Route path="/menu_apr/edit_movement/:movementId" element={<MovementEdit />} />

						<Route path='/menu_apr/clients_states' element={<ClientsStates />}/>
						<Route path="/menu_apr/create_client_type" element={<ClientStateCreate />} />
						<Route path="/menu_apr/edit_client_type/:clientTypeId" element={<ClientStateEdit />} />

						<Route path='/menu_apr/payment_methods' element={<PaymentMethods />}/>
						<Route path="/menu_apr/create_payment_method" element={<PaymentMethodCreate />} />
						<Route path="/menu_apr/edit_payment_method/:methodId" element={<PaymentMethodEdit />} />


						{/* USERS SUBMENU */}
						<Route path='/menu_apr/users_list' element={<UserLists />}/>
						<Route path='/menu_apr/clients_movements' element={<ClientsMovements />}/>
						<Route path='/menu_apr/add-charge/' element={<AddCharge />}/>
						<Route path='/menu_apr/edit-charge/:chargeId' element={<EditCharge />}/>
						<Route path='/menu_apr/user-charges/:userId' element={<UserCharges />}/>
						<Route path='/menu_apr/reading_entries' element={<ReadingEntries />}/>
						<Route path='/menu_apr/requirements_spreadsheets' element={<RequirementsSpreadsheets />}/>
						<Route path='/menu_apr/monthly-lectures/:userId' element={<MonthlyLectures />}/>
						<Route path='/menu_apr/pay/:userId' element={<Pay />}/>
						<Route path='/menu_apr/renegotiate/:userId/:invoiceId' element={<Renegotiate />} />



						<Route path='/menu_apr/file_api' element={<FileApi />}/>
						<Route path="/menu_apr/editar-usuario/:userId" element={<EditUser />} />
						<Route path="/menu_apr/add-user/" element={<AddUser />} />
						<Route path="/menu_apr/consumptions/:userId" element={<Consumptions />} />
						<Route path="/menu_apr/agregar-lectura/:meterId" element={<AddLecture />} />
					</Route>
				</Routes>
			</Router>
			<ToastContainer />
		</>
	);
}

function WithNavAndFooter() {
	return (
		<>
			<div className="main">
				<Nav />
				<Outlet /> {/* Aquí se renderizarán los elementos hijos de las rutas */}
				<Footer />
			</div>
		</>
	);
}

function WithoutNavAndFooter() {
	return (
		<>
			<div className="background-overlap" />
			<div className="main-menu">
				<MenuNav />
				<Outlet />
			</div>
		</>
	);
}

export default App;
