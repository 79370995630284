import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import axios from "axios";
import {BACKEND_DOMAIN} from "../../../../features/auth/authService";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import saveDisk from "../../../../images/save-disk.svg";
import cancelIcon from "../../../../images/cancel-icon.svg";

export default function AddUser() {
	const navigate = useNavigate();
	const {userId} = useParams(); // Extraer userId desde la URL
	const [userInfo, setUserInfo] = useState(null); // Estado para la información del usuario
	const [sectores, setSectores] = useState([]); // Estado para los sectores
	const [medidores, setMedidores] = useState([]); // Estado para los medidores

	const [formData, setFormData] = useState({
		first_name: "",
		last_name: "",
		rut: "",
		email: "",
		address: "",
		id_medidor: "",
		sector: "",
	});

	useEffect(() => {

		const fetchSectores = async () => {
			try {
				const response = await axios.get(`${BACKEND_DOMAIN}/api/v1/sectores/`);
				setSectores(response.data); // Guardar los sectores en el estado
			} catch (error) {
				console.error("Error al obtener los sectores:", error);
				toast.error("Error al obtener los sectores");
			}
		};

		const fetchMedidores = async () => {
			// Obtener el token desde localStorage
			const user = JSON.parse(localStorage.getItem("user"));
			const token = user ? user.access : null;

			// Verificar que el token esté disponible
			if (!token) {
				toast.error("Token de autenticación no disponible");
				return;
			}

			try {
				const response = await axios.get(`${BACKEND_DOMAIN}/api/v1/getmedidores/apr/`, {
					headers: {
						Authorization: `Bearer ${token}`, // Incluir el token en los headers
					},
				});
				setMedidores(response.data); // Guardar los medidores en el estado
			} catch (error) {
				console.error("Error al obtener los medidores:", error);
				toast.error("Error al obtener los medidores");
			}
		};

		// fetchUserInfo();
		fetchSectores();
		fetchMedidores();
	}, [userId]);

	// Actualizar el formData cuando userInfo cambie
	useEffect(() => {
		if (userInfo) {
			setFormData({
				first_name: userInfo.first_name || "",
				last_name: userInfo.last_name || "",
				rut: userInfo.rut || "",
				email: userInfo.email || "",
				address: userInfo.address || "",
				id_medidor: userInfo.meter_addr || "",
				sector: userInfo.sector || "",
			});
		}
	}, [userInfo]);

	const handleChange = (e) => {
		setFormData((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		// Crear objeto con solo los campos que han sido modificados
		const dataToUpdate = {};
		Object.keys(formData).forEach((key) => {
			const value = formData[key];

			// Verifica si el valor es una cadena antes de usar trim
			if (typeof value === "string" && value.trim()) {
				dataToUpdate[key] = value;
			} else if (value) {
				// Si no es una cadena pero tiene un valor (por ejemplo, un número), lo añade directamente
				dataToUpdate[key] = value;
			}
		});

		// Obtener el token desde localStorage
		const user = JSON.parse(localStorage.getItem("user"));
		const token = user ? user.access : null;

		// Solo hacer la solicitud si hay datos a actualizar y hay un token
		if (Object.keys(dataToUpdate).length > 0 && token) {
			try {
				const response = await axios.put(`${BACKEND_DOMAIN}/api/v1/user/update/${userId}/`, dataToUpdate, {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});

				toast.success("Cambios guardados con éxito");
				navigate("/menu_apr/users_list");
			} catch (error) {
				console.error("Error al actualizar los datos:", error.response ? error.response.data : error.message);
				toast.error("Error al actualizar los datos");
			}
		} else {
			toast.error("No hay datos para actualizar o falta el token");
		}
	};

	return (
		<div className="menu-container">
			<div className="menu-content-container">
				<div className="my-profile-container">
					<div className="form-container">
						<h1 className="form-title">Datos Usuario</h1>
						<form className="edit-user-form" onSubmit={handleSubmit}>
							<div className="input-container">
								<label>Nombre</label>
								<input type="text" name="first_name" value={""} onChange={handleChange} />
							</div>
							<div className="input-container">
								<label>Apellido</label>
								<input type="text" name="last_name" value={""} onChange={handleChange} />
							</div>
							<div className="input-container">
								<label>Rut</label>
								<input type="text" name="rut" value={""} onChange={handleChange} />
							</div>
							<div className="input-container">
								<label>Email</label>
								<input type="text" name="email" value={""} onChange={handleChange} />
							</div>
							<div className="input-container">
								<label>Dirección</label>
								<input type="text" name="address" value={""} onChange={handleChange} />
							</div>
							<div className="input-container">
								<label>Id Medidor</label>
								<select name="id_medidor" value={""} onChange={handleChange}>
									<option value="">Selecciona un medidor</option>
									{medidores.map((medidor) => (
										<option key={medidor.id} value={medidor.meter_addr}>
											{medidor.meter_addr}
										</option>
									))}
								</select>
							</div>
							<div className="input-container">
								<label>Sector</label>
								<select name="sector" value={formData.sector} onChange={handleChange}>
									<option value="">Selecciona un sector</option>
									{sectores.map((sector) => (
										<option key={sector.id} value={sector.id}>
											{sector.sector_name}
										</option>
									))}
								</select>
							</div>
							<div className="buttons-container">
								<button type="button" className="btn-cancel" onClick={() => navigate("/menu_apr")}>
									<img src={cancelIcon} className="cancel-image" />
									Cancelar
								</button>
								<button type="submit" className="btn-save">
									<img src={saveDisk} className="save-image" />
									Guardar
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}
