import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import saveDisk from "../../../../images/save-disk.svg";
import cancelIcon from "../../../../images/cancel-icon.svg";
import { Button, Input, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {BACKEND_DOMAIN} from "../../../../features/auth/authService";


export default function UserCharges() {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState(null);
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const { userId } = useParams(); // Extraer el userId de la URL


    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const handleEditClick = (chargeId) => {
        // Redirigir al componente de edición pasando el ID del usuario
        navigate(`/menu_apr/edit-charge/${chargeId}`);
    };

    const columns = [
        {
            title: "Año",
            dataIndex: "year",
            key: "year",
            width: "10%",
            ...getColumnSearchProps("year"),
        },
        {
            title: "Mes",
            dataIndex: "month",
            key: "month",
            width: "10%",
            ...getColumnSearchProps("month"),
        },
        {
            title: "Tipo de movimiento",
            dataIndex: "movement_type",
            key: "movement_type",
            width: "30%",
            ...getColumnSearchProps("movement_type"),
        },
        {
            title: "Total mes",
            dataIndex: "month_total",
            key: "month_total",
            width: "20%",
            ...getColumnSearchProps("month_total"),
        },
        {
            title: "Acción",
            dataIndex: "edit",
            key: "edit",
            render: (_, record) => (
                <div className="actions-container">
                    <a className="active-link" onClick={() => handleEditClick(record.key)}>
                        Editar
                    </a>
                </div>
            ),
        },
    ];


    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const user = JSON.parse(localStorage.getItem("user"));
                const token = user?.access;

                if (!token) {
                    throw new Error("No se encontró el token en el localStorage");
                }

                const response = await fetch(`${BACKEND_DOMAIN}/api/v1/user/${userId}/`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });

                const result = await response.json();

                if (response.ok) {
                    const userInfo = {
                        name: `${result.first_name} ${result.last_name}`,
                        meter: result.meter_addr,
                        rut: result.rut,
                        address: result.address,
                    };

                    setUserInfo(userInfo);
                } else {
                    console.error("Error al obtener la información del usuario:", result);
                }
            } catch (error) {
                console.error("Error en la petición:", error.message);
            }
        };

        fetchUserInfo();
    }, []);


    useEffect(() => {
        const fetchCargos = async () => {
            try {
                const user = JSON.parse(localStorage.getItem("user"));
                const token = user?.access;

                if (!token) {
                    throw new Error("No se encontró el token en el localStorage");
                }

                const response = await fetch(`${BACKEND_DOMAIN}/api/v1/cargos/usuario/${userId}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });

                const result = await response.json();

                if (response.ok) {
                    const mappedData = result.map((cargo) => ({
                        key: cargo.id,
                        year: new Date(cargo.fecha_emision_cargo).getFullYear().toString(),
                        month: new Date(cargo.fecha_emision_cargo).toLocaleString("es-ES", { month: "long" }),
                        costo: cargo.costo,
                        movement_type: cargo.movimiento_fk.descripcion,
                        month_total: cargo.costo,
                    }));

                    // Ordenar los cargos por fecha_emision_cargo de forma ascendente
                    const sortedData = mappedData.sort((a, b) => new Date(a.fecha_emision_cargo) - new Date(b.fecha_emision_cargo));

                    setData(sortedData);
                } else {
                    console.error("Error al obtener los cargos:", result);
                }
            } catch (error) {
                console.error("Error en la petición:", error.message);
            }
        };

        fetchCargos();
    }, []);


    return (
        <div className="menu-container">
            <div className="menu-content-container">
                <div className="top-gray-container small">
                    <div className="user-info-container">
                        <h3 className="info-text">
                            Nombre: <span className="marked">{userInfo?.name}</span>
                        </h3>
                        <h3 className="info-text">
                            N° Medidor: <span className="marked">{userInfo?.meter}</span>
                        </h3>
                        <h3 className="info-text">
                            RUT: <span className="marked">{userInfo?.rut}</span>
                        </h3>
                        <h3 className="info-text">
                            Dirección: <span className="marked">{userInfo?.address}</span>
                        </h3>
                    </div>
                </div>
                <div className="renegotiate-main-container">
                    <h1 className="form-title">Cargos usuario:</h1>
                    <div className='bottom-table-container'>
                        <Table columns={columns} dataSource={data} />

                    </div>
                </div>
            </div>
        </div>
    )
}
