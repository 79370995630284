import { Button, Input, Space, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useNavigate } from "react-router-dom";
import plusIcon from "../../../images/plus-icon.svg";
import axios from "axios";
import {BACKEND_DOMAIN} from "../../../features/auth/authService";



export default function MovementsLists() {
	const [data, setData] = useState([]);
	const searchInput = useRef(null);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");
	const [error, setError] = useState(null);

	const navigate = useNavigate();

	const handleEditClick = (movementId) => {
		// Redirigir al componente de edición pasando el ID del movimiento
		// navigate(`/menu_apr/editar-usuario/${userId}`);
		navigate(`/menu_apr/edit_movement/${movementId}`);
	};
	const handleAddClick = () => {
		navigate(`/menu_apr/create_movement/`);
	};

	const handleDeleteClick = (userId) => {
		// navigate(`/menu_apr/consumptions/${userId}`);
	};

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					ref={searchInput}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ marginBottom: 8, display: "block" }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}
					>
						Reset
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm({ closeDropdown: false });
							setSearchText(selectedKeys[0]);
							setSearchedColumn(dataIndex);
						}}
					>
						Filter
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
							confirm();
						}}
					>
						close
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />,
		onFilter: (value, record) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ""}
				/>
			) : (
				text
			),
	});

	const columns = [
		{
			title: "Código",
			dataIndex: "codigo",
			key: "codigo",
			...getColumnSearchProps("codigo"),
		},
		{
			title: "Descripción",
			dataIndex: "descripcion",
			key: "descripcion",
			...getColumnSearchProps("descripcion"),
		},
		{
			title: "Tipo",
			dataIndex: "tipo",
			key: "tipo",
			...getColumnSearchProps("tipo"),
		},
		{
			title: "Acción",
			dataIndex: "edit",
			key: "edit",
			render: (_, record) => (
				<div className="actions-container">
					<a className="active-link" onClick={() => handleEditClick(record.key)}>
						Editar
					</a>
					<a className="review-link" onClick={() => handleDeleteClick(record.key)}>
						Eliminar
					</a>
				</div>
			),
		},
	];

	useEffect(() => {
		const fetchMovements = async () => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				const token = user?.access;

				if (!token) {
					throw new Error("No se encontró el token en el localStorage");
				}

				const response = await axios.get(`${BACKEND_DOMAIN}/api/v1/movimiento/apr/`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				const movements = response.data;
				const mappedData = movements.map((movement) => ({
					key: movement.id,
					codigo: movement.codigo,
					descripcion: movement.descripcion,
					tipo: movement.tipo,
					assignedApr: movement.assigned_apr,
				}));

				setData(mappedData);
			} catch (error) {
				console.error("Error fetching movements:", error);
				setError(error.message);
			}
		};

		fetchMovements();
	}, []);



	return (
		<div className="menu-container">
			<div className="menu-content-container">
				<div className="top-gray-container small">
					<h1 className="container-title">Tipos de movimientos</h1>
					<div className="add-button-container">
						<img className="plus-icon" src={plusIcon} />
						<a className="add-button" onClick={() => handleAddClick("")}>
							Añadir tipo de movimiento
						</a>
					</div>
				</div>
				<Table columns={columns} dataSource={data} />
			</div>
		</div>
	);
}
