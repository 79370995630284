import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
import 'react-toastify/dist/ReactToastify.css'
import saveDisk from "../../../../images/save-disk.svg"
import cancelIcon from "../../../../images/cancel-icon.svg"
import {BACKEND_DOMAIN} from "../../../../features/auth/authService";

export default function MovementCreate() {
    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        codigo: '',
        descripcion: '',
        tipo: 'Debe', // Default value set as 'Debe'
        assigned_apr: '', // This will be filled dynamically
    })

    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const user = JSON.parse(localStorage.getItem('user'))
        const token = user?.access

        if (!token) {
            toast.error('Token de autenticación no disponible')
            return
        }

        let updatedFormData = { ...formData } // Clonamos el estado actual

        try {
            const response = await axios.get(`${BACKEND_DOMAIN}/api/v1/user-detail/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })

            const userData = response.data
            updatedFormData = {
                ...updatedFormData,
                assigned_apr: userData.assigned_apr !== undefined ? userData.assigned_apr : '',
            }

            toast.success('Datos del usuario cargados correctamente')
        } catch (error) {
            console.error('Error al obtener detalles del usuario:', error.response ? error.response.data : error.message)
            toast.error('Error al obtener detalles del usuario')
            return // Detenemos la ejecución si hay un error en la solicitud GET
        }

        try {
            const response = await axios.post(
                `${BACKEND_DOMAIN}/api/v1/movimiento/create/`,
                updatedFormData, // Usamos el objeto actualizado
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            )

            toast.success('Movimiento creado con éxito')
            navigate('/menu_apr/movements_list/') // Redirige al listado de movimientos o a la página deseada
        } catch (error) {
            console.error('Error al crear el movimiento:', error.response ? error.response.data : error.message)
            toast.error('Error al crear el movimiento')
        }
    }

    return (
        <div className="menu-container">
            <div className="menu-content-container">
                <div className="my-profile-container">
                    <div className="form-container">
                        <h1 className="form-title">Crear Nuevo Movimiento</h1>
                        <form className="edit-user-form" onSubmit={handleSubmit}>
                            <div className="input-container">
                                <label>Código</label>
                                <input
                                    type="text"
                                    name="codigo"
                                    value={formData.codigo}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="input-container">
                                <label>Descripción</label>
                                <input
                                    type="text"
                                    name="descripcion"
                                    value={formData.descripcion}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="input-container">
                                <label>Tipo</label>
                                <select
                                    name="tipo"
                                    value={formData.tipo}
                                    onChange={handleChange}
                                >
                                    <option value="Debe">Debe</option>
                                    <option value="Haber">Haber</option>
                                </select>
                            </div>
                            <div className="buttons-container">
                                <button
                                    type="button"
                                    className="btn-cancel"
                                    onClick={() => navigate('/menu_apr')}
                                >
                                    <img src={cancelIcon} className="cancel-image" />
                                    Cancelar
                                </button>
                                <button type="submit" className="btn-save">
                                    <img src={saveDisk} className="save-image" />
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
